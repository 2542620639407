const api = {
    // if beta client redirect to beta environment
    baseUrl: (typeof process.env.API_ENDPOINT != 'undefined' ? process.env.API_ENDPOINT : (process.env.NODE_ENV=="production" ? "https://api.portal.sprwt.io/v1" : "https://dev.api.portal.sprwt.io/v1")),
    /* baseUrl: "https://api.rootplanner.io/v1" */
    /* baseUrl: "http://192.168.10.23:4000/v1" */ //no trialing slash here
    testUrl: "http://localhost:4000/v1",
    postmanUrl: "https://1e3eb7dd-f275-4bc5-b181-4fcd27ba4c5a.mock.pstmn.io",
    localUrl: "http://localhost:3001/v1",
    // baseUrl: "http://localhost:4000/v1"
};

export default api;
