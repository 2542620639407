import { createSlice } from '@reduxjs/toolkit'


export const tasksSlice = createSlice({
    name: 'tasks',
    initialState: {
        value: {
            tasks: {},
            openAddTaskModal: false,
            onlyOnboarderTasks: false
        }
    },
    reducers: {
        updateTask: (state, { payload }) => {

            const { taskId, taskPayload } = payload

            if (!taskId || !taskPayload) return


            delete taskPayload?.questions

            if (state.value.tasks[taskId]?.questionsIds) {
                delete taskPayload.questionsIds
            }

            state.value.tasks[taskId] = {
                ...state.value.tasks[taskId],
                ...taskPayload,
                dontUpdate: false
            }

        },
        skiptUpdate: (state, { payload: { taskId } = {} }) => {

            state.value.tasks[taskId] ??= {}
            state.value.tasks[taskId].dontUpdate = true

        },
        addNewOnboarderTaskToTask: (state, { payload: onboarderTask }) => {

            const { taskId, parentTaskId } = onboarderTask

            if (!parentTaskId || !taskId) return

            const taskIndex = state.value.tasks[parentTaskId]?.onboarderTasksIds?.indexOf(taskId)

            //if tasks is already saved then return
            if (taskIndex && taskIndex !== -1) return


            state.value.tasks[parentTaskId] ??= { onboarderTasks: {} }

            state.value.tasks[parentTaskId].onboarderTasks ??= {}

            state.value.tasks[parentTaskId].onboarderTasks[taskId] = onboarderTask

            state.value.tasks[parentTaskId].onboarderTasksIds ??= []

            state.value.tasks[parentTaskId].onboarderTasksIds?.push(taskId)


        },
        removeOnboardTaskFromTask: (state, { payload }) => {

            const { taskId, parentTaskId } = payload

            if (!taskId || !parentTaskId) return

            state.value.tasks[parentTaskId] ??= { onboarderTasks: [] }

            const { isNew } = state.value.tasks[parentTaskId].onboarderTasks?.[taskId] || {}

            if (isNew) {
                delete state.value.tasks[parentTaskId].onboarderTasks?.[taskId]
                state.value.tasks[parentTaskId].onboarderTasksIds = state.value.tasks[parentTaskId].onboarderTasksIds?.filter?.((onboarderTask) => onboarderTask !== taskId)
            } else {
                state.value.tasks[parentTaskId].onboarderTasks ??= {}
                state.value.tasks[parentTaskId].onboarderTasks[taskId] ??= {}
                state.value.tasks[parentTaskId].onboarderTasks[taskId].isDeleted = true
            }

        },
        recoverOnboardTaskFromTask: (state, { payload }) => {

            const { taskId, parentTaskId } = payload

            if (!taskId || !parentTaskId) return

            state.value.tasks[parentTaskId] ??= { onboarderTasks: {} }

            state.value.tasks[parentTaskId].onboarderTasks[taskId] ??= {}

            state.value.tasks[parentTaskId].onboarderTasks[taskId].isDeleted = false

        },
        addQuestionToTask: (state, { payload: question }) => {

            const { questionId, isNew, taskId, isNewSaved } = question

            if (!questionId || (!isNew && !isNewSaved)) return


            state.value.tasks[taskId] ??= { questions: {} }

            state.value.tasks[taskId].questions ??= {}

            state.value.tasks[taskId].questions[questionId] = question

            state.value.tasks[taskId].questionsIds ??= []

            state.value.tasks[taskId].questionsIds?.push(questionId)

        },
        removeQuestionFromTask: (state, { payload }) => {

            const { taskId, questionId } = payload

            if (!taskId) return

            state.value.tasks[taskId] ??= { questions: [] }

            const { isNew } = state.value.tasks[taskId].questions?.[questionId] || {}

            if (isNew) {
                delete state.value.tasks[taskId].questions?.[questionId]
                state.value.tasks[taskId].questionsIds = state.value.tasks[taskId].questionsIds?.filter?.((question) => question !== questionId)
            } else {
                state.value.tasks[taskId].questions ??= {}
                state.value.tasks[taskId].questions[questionId] ??= {}
                state.value.tasks[taskId].questions[questionId].isDeleted = true
            }

        },
        recoverQuestionFromTask: (state, { payload }) => {

            const { taskId, questionId } = payload

            if (!taskId) return

            state.value.tasks[taskId] ??= { questions: {} }

            state.value.tasks[taskId].questions[questionId] ??= {}

            state.value.tasks[taskId].questions[questionId].isDeleted = false

        },
        //This action expects the questionsIds sorted
        resortQuestions: (state, { payload }) => {

            const { taskId, questionsSorted } = payload

            if (!Array.isArray(questionsSorted)) return

            state.value.tasks[taskId].questionsIds = questionsSorted

        },
        addPlanModuleToTask: (state, { payload: { module, plan, taskId } }) => {

            if ((!module && !plan) || !taskId) return

            //if module/plan is already saved then return
            if (state.value.tasks[taskId]?.modules?.[module?.moduleId]) return
            if (state.value.tasks[taskId]?.plans?.[plan?.planId]) return

            state.value.tasks[taskId] ??= { plans: {}, modules: {} }

            if (module) {

                state.value.tasks[taskId].modules ??= {}
                state.value.tasks[taskId].modules[module.moduleId] = { ...module, isNew: true }
                return

            }

            state.value.tasks[taskId].plans ??= {}
            state.value.tasks[taskId].plans[plan.planId] = { ...plan, isNew: true }

        },
        removePlanModuleFromTask: (state, { payload: { moduleId, planId, taskId } }) => {

            if ((!moduleId && !planId) || !taskId) return

            state.value.tasks[taskId] ??= { plans: {}, modules: {} }

            if (moduleId) {

                const { isNew } = state.value.tasks[taskId].modules?.[moduleId] || {}

                if (isNew) {
                    delete state.value.tasks[taskId].modules[moduleId]
                    return
                }

                state.value.tasks[taskId].modules[moduleId] ??= {}
                state.value.tasks[taskId].modules[moduleId].isDeleted = true

                return
            }

            const { isNew } = state.value.tasks[taskId].plans?.[planId] || {}

            if (isNew) {
                delete state.value.tasks[taskId].plans[planId]
                return
            }

            state.value.tasks[taskId].plans[planId] ??= {}
            state.value.tasks[taskId].plans[planId].isDeleted = true

        },
        cleanTasksToUpdate: (state) => {
            state.value.tasks = {}
        },
        openAddTaskModal: (state, { payload }) => {
            state.value.taskModalOpen = true
            state.value.taskModalPhase = payload.phaseId
            state.value.onlyOnboarderTasks = payload.onlyOnboarderTasks
            state.value.parentTaskId = payload.parentTaskId
        },
        closeAddTaskModal: (state) => {
            state.value.taskModalOpen = false
            state.value.taskModalPhase = undefined
            state.value.onlyOnboarderTasks = false
            state.value.parentTaskId = undefined
        },
        recoverPlanModuleFromTask: (state, { payload: { moduleId, planId, taskId } }) => {

            if (!taskId) return

            if (moduleId) {

                state.value.tasks[taskId] ??= { modules: {} }
                state.value.tasks[taskId].modules[moduleId] ??= {}

                state.value.tasks[taskId].modules[moduleId].isDeleted = false

                return
            }

            state.value.tasks[taskId] ??= { plans: {} }
            state.value.tasks[taskId].plans[planId] ??= {}

            state.value.tasks[taskId].plans[planId].isDeleted = false

        }
    },
})

export const { updateTask,
    cleanTasksToUpdate,
    openAddTaskModal,
    closeAddTaskModal,
    addQuestionToTask,
    removeQuestionFromTask,
    recoverQuestionFromTask,
    skiptUpdate,
    resortQuestions,
    addNewOnboarderTaskToTask,
    removeOnboardTaskFromTask,
    recoverOnboardTaskFromTask,
    addPlanModuleToTask,
    removePlanModuleFromTask,
    recoverPlanModuleFromTask,
} = tasksSlice.actions

export default tasksSlice.reducer