import { createSlice } from '@reduxjs/toolkit'

export const modulesSlice = createSlice({

    name: 'modules',
    initialState: { value: { selectedModule: {}, modules: {} } },
    reducers: {
        setModule: (state, { payload }) => {
            state.value = { ...state.value, selectedModule: payload }
        },
        updateModule: (state, { payload }) => {

            const { moduleId, module } = payload

            state.value.modules[moduleId] = {
                ...state.value.modules[moduleId],
                ...module
            }

        },
    },
})

export const { updateModule } = modulesSlice.actions

export default modulesSlice.reducer