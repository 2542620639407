import React from 'react';
import { Link } from 'react-router-dom';

import {
    Sidebar,
    UncontrolledTooltip
} from './../../../components';

import { VersionSelector } from '../VersionSelector';
import { LogoThemed } from './../../../routes/components/LogoThemed/LogoThemed';
import sprwtIcon from '../../../images/logos/sprwt_logo_v3-.png';
import sprwtSmallIcon from '../../../images/logos/sprwt-small-icon.png'
import useFromLocalStorage from '../../../hooks/useFromLocalStorage';

const SidebarTopB = () => {

    const isCustomerview = false;
    const isOnAdminMode = sessionStorage.getItem('adminMode') === 'admin'
    const companyId = useFromLocalStorage('company')?.companyId

    return (
        <React.Fragment>
            { /* START Sidebar TOP: B */}
            { /* START DESKTOP View */}
            <Sidebar.HideSlim>
                {
                    isOnAdminMode ? (
                        <Link to="/" className="sidebar__brand">
                            Dashboard
                        </Link>
                    ) :
                        <Link
                            to={`/companies/${companyId}/subscriptions`}

                        >
                            <img
                                src={sprwtIcon}
                                style={{
                                    height: '1.8em',
                                }}
                            />
                        </Link>
                }
            </Sidebar.HideSlim>
            <Sidebar.HideSlim>
                <div>
                    <div className="d-flex">
                        {/* <Link to="/" className="align-self-center sidebar__brand" id="tooltipBackToHome">
                            <i className="fa fa-send fa-fw fa-2x"></i>
                        </Link> */}
                        {/* <UncontrolledTooltip placement="right" target="tooltipBackToHome">
                            Back to Home
                        </UncontrolledTooltip> */}

                        {/* <VersionSelector
                            down
                            sidebar
                            dashboard="Airframe"
                            render={(currentVersion) => (
                                <React.Fragment>
                                    <div className="h4 fw-600 sidebar-logo mb-1 text-left">
                                        react.bs4 <i className="fa fa-angle-down ml-1 sidebar__link--muted"></i>
                                    </div>
                                    <div
                                        className="job-title small text-left sidebar__link--muted"
                                    >
                                        Version: {currentVersion.label}, {currentVersion.version}
                                    </div>
                                </React.Fragment>
                            )}
                        /> */}
                    </div>
                </div>
            </Sidebar.HideSlim>
            { /* END DESKTOP View */}
            { /* START SLIM Only View */}
            <Sidebar.ShowSlim>
                <div className="text-center">
                    <Link to="/">
                        <img
                            id="tooltipBackToHomeSlim"
                            src={sprwtSmallIcon} style={{
                                height: '1.2em',
                            }} />
                    </Link>
                    <UncontrolledTooltip placement="right" target="tooltipBackToHomeSlim">
                        Back to Home
                    </UncontrolledTooltip>
                </div>
            </Sidebar.ShowSlim>
            { /* END SLIM Only View  */}
            { /* END Sidebar TOP: B */}
        </React.Fragment>
    )
}

export { SidebarTopB };
