import React, { useState, useEffect } from 'react'

export default function useUserPicture() {

    const [userPicture, setUserPicture] = useState(null)

    useEffect(() => {

        let user = {}

        try {
            user = JSON.parse(localStorage.user)
        }
        catch (err) {
            console.error({ err })
        }

        if (user && user.user && user.user.picture) {
            setUserPicture(user.user.picture)
        }

    }, [])

    return [userPicture, setUserPicture]
}