import React from 'react';
import axios from 'axios';
import config from '../../../../../api'
let axiosConfig = {headers: {Authorization: localStorage.token}};

const PaymentMethodsTable = (props) => {
    function deleteCard(card){
        axios.delete(`${config.baseUrl}/superadmin/companies/`+ props.company.companyId + `/paymentMethods/` + card.stripeCardId, axiosConfig)
        .then(function (response) {
            props.reloadParent()
        })
    }
    return(
    <React.Fragment>
        {
            props.pms ? props.pms.map((value, key) => {
                return(
                    <tr>
                        {/* <td className="align-middle">
                            A
                        </td> */}

                        <td className="align-middle">
                            {value.card.brand}
                        </td>

                        <td className="align-middle">
                            **** **** **** {value.card.last4}
                        </td>

                        <td className="align-middle">
                            {value.card.exp_month}/{value.card.exp_year}
                        </td>
                        <td className="align-middle">
                            <button onClick={(e) => deleteCard(value)} className="btn btn-link"><i className="fa fa-fw fa-times text-danger"></i></button>
                        </td>
                    </tr>
                )
            })
            : ""
        }
    </React.Fragment>
    )
}

export { PaymentMethodsTable };
