import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import config from "../../api";
import axios from 'axios'
import { Link } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import changelog from "../../changelog"


import { 
    Card,
    CardBody,
    Row,
    Col
} from '../components';

let axiosConfig = { headers: { Authorization: localStorage.token } };

const ChangeLog = (props) => {
    const [backChanges, setBackChanges] = useState(false)
    const [backVersion, setBackVersion] = useState(false)

    useEffect(() => {
        getBackChanges()
        getBackVersion()
    }, []);

    function getBackChanges(){
        axios.get(`${config.baseUrl}/superadmin/changelog`, axiosConfig)
        .then(response => {
            if(response.data && response.data.data){
                setBackChanges(response.data.data)
            } else {
                console.log("Could not retrieve changelog")
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    function getBackVersion(){
        axios.get(`${config.baseUrl}/superadmin/version`, axiosConfig)
        .then(response => {
            if(response.data && response.data.data){
                setBackVersion(response.data.data)
            } else {
                console.log("Could not retrieve version")
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    return (
        <React.Fragment>
            <div className=" d-flex justify-content-between align-items-center">
                <div className="d-flex mb-5 mt-4">
                    <h1 className="display-4 mr-3 mb-0 align-self-start">
                        <Link to="/superAdmin"><IoIosArrowRoundBack /></Link>
                        Changelog
                    </h1>
                </div>
            </div>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>Front-End</h2>
                        </CardBody>
                        <CardBody>
                            {changelog.map((version, key) => {
                                return (
                                    <>
                                        <span key={key}>
                                            <h4>{version.version} {key==0 ? "(latest)" : ""}</h4>
                                            <div>Date: {version.date}</div>
                                            <div>Release: {version.release}</div>
                                            <div>Phase: {version.phase}</div>
                                            <h4 className="mt-4">Fixes:</h4>
                                            {version.changes.fixes.map((line, key) => {
                                                return(
                                                    <li style={{lineHeight: "2em"}} key={key}>{line}</li>
                                                )
                                            })}
                                            <h4 className="mt-4">Rebuilt/Reworked:</h4>
                                            {version.changes.rebuild.map((line, key) => {
                                                return(
                                                    <li style={{lineHeight: "2em"}} key={key}>{line}</li>
                                                )
                                            })}
                                            <h4 className="mt-4">Features:</h4>
                                            {version.changes.features.map((line, key) => {
                                                return(
                                                    <li style={{lineHeight: "2em"}} key={key}>{line}</li>
                                                )
                                            })}
                                        </span>
                                        <hr/>
                                    </>
                                )
                            })}
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <CardBody>
                            <h2>API</h2>
                            {config.baseUrl}
                        </CardBody>
                        {backVersion ?
                            <CardBody>
                                <h5>{backVersion.version} (latest)</h5>
                                <div className="mt-4">
                                    {backVersion.changes.map((line, key) => {
                                        return(
                                            <li key={key}>{line}</li>
                                        )
                                    })}
                                </div>
                            </CardBody>
                        : <CardBody> No back-end version available </CardBody>}
                        {backChanges ?
                            backChanges.map((line, key) => {
                                return(
                                    <CardBody key={key}>
                                        <h5>Version {line.v} - {line.date} - {line.author}</h5>
                                        <div className="mt-4">
                                            {line.changes.map((line, key) => {
                                                return(
                                                    <li key={key}>{line}</li>
                                                )
                                            })}
                                        </div>
                                    </CardBody>
                                )
                            })
                        : <CardBody> No back-end backlog available </CardBody>}
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default withRouter(ChangeLog);
