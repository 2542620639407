import React from 'react';

import { SidebarMenu } from '../../components'; 
import {FormattedMessage} from "react-intl";


import translate from "../../i18nProvider/translate";

// title={translate('edit-file', {file: <code>src/App.js</code>})}

export const SidebarWizardNav = () => (
    <SidebarMenu>
        <SidebarMenu.Item
            icon={<i className="fa fa-fw fa-magic"></i>}
            title="Get Started"
            to='/wizard' 
            exact
        ></SidebarMenu.Item>
    </SidebarMenu >
);
