import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import config from "../../../../../api";
import axios from 'axios'

let axiosConfig = {headers: {Authorization: localStorage.token} };

import {
    Card,
    CardBody,
    Input,
    Row,
    Col,
    Button,
    Alert
} from './../../../../components';

export class ConfigTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: [],
            countries: [],
            timezones: [],
            editingCell: false,
            countries: false,
            timezones: false,
        }
    }
    

    componentDidMount() {
        this.setState({company: this.props.company})
        this.getCountries()
        this.getTimezones()
    }

    /* Countries  */
    getCountries(){
        const me = this
        axios.get(`${config.baseUrl}/globals/countries`, axiosConfig)
        .then(function (response){
            if(response.data.data){
                me.setState({ countries: response.data.data })
            }
        })
    }

    /* Timzones */
    getTimezones(){
        const me = this
        axios.get(`${config.baseUrl}/globals/timezones`, axiosConfig)
        .then(function (response){
            if(response.data.data){
                console.log(response.data.data)
                me.setState({ timezones: response.data.data })
            } else {
                me.setState({timezones: true})
            }
        })
    }

    handleType(type){
        switch(type){
            case "string":
                return "text"
            case "number":
                return "number"
        }
    }

    handleChange(value, key){
        let temp = this.state.company
        for(const i in this.state.company){
            if(this.state.company[i].key == key){
                this.state.company[i].value = value
            }
        }
        this.setState({ company: temp })
    }

    CustomInput = (value) => {
        switch(value.key){
            case "driverMode":
                return (
                    <Input 
                        type="select" 
                        name="driverMode" 
                        bsSize="sm"
                        id="form_driverMode" 
                        value={value.value}
                        onChange={(e) => this.updateCompany(e.target.value, "driverMode")} 
                    >
                        <option value="1">Drivers without zone can deliver anywhere</option>
                        <option value="0">Suspend Zones</option>
                        <option value="2">Restrict Orders in Area to Driver</option>
                        <option value="3">Drivers can only deliver in their zone</option>
                    </Input>
                )
            case "distanceMeasure":
                return (
                    <Input 
                        type="select" 
                        name="distanceMeasure" 
                        bsSize="sm"
                        id="form_distanceMeasure" 
                        value={value.value}
                        onChange={(e) => this.updateCompany(e.target.value, "distanceMeasure")} 
                    >
                        <option value="1">Imperial</option>
                        <option value="0">Metric</option>
                    </Input>
                )
            case "timezone":
                return (
                    <Input 
                        type="select" 
                        name="timezone" 
                        bsSize="sm"
                        id="form_timezone" 
                        value={value.value}
                        onChange={(e) => this.updateCompany(e.target.value, "timezone")} 
                    >
                        {this.state.timezones ? this.state.timezones.map((timezone, key) => {
                            return <option value={timezone.key}>{timezone.value}</option>
                        }) : ""}
                    </Input>
                )
            case "country":
                return(
                    <>
                        <Input 
                            type="select"
                            name="country"
                            bsSize="sm"
                            value={value.value ? value.value : "US"}
                            onChange={(e) => this.updateCompany(e.target.value, "country")} // aqui escolhes a key
                        >
                            {this.state.countries ? Object.entries(this.state.countries).map((country, key) => {
                                return <option value={country[0]}>{country[1]}</option>
                            }) : ""}
                        </Input>
                    </>
                )
            case "defaultOrderStatusId":
                return(
                    <>
                        <Input 
                            type="select"
                            name="status"
                            bsSize="sm"
                            value={value.value}
                            onChange={(e) => this.updateCompany(e.target.value, "defaultOrderStatusId")} // aqui escolhes a key
                        >
                            {/* {this.orderStatus.map((value, key) => {
                                return(
                                    <option key={key} value={value}>MANUAL</option>
                                )
                            })} */}
                        </Input>
                    </>
                )
            case "status":
                return(
                    <>
                        <Input 
                            type="select"
                            name="status"
                            bsSize="sm"
                            value={value.value}
                            onChange={(e) => this.updateCompany(e.target.value, "status")} // aqui escolhes a key
                        >
                            <option key="0" value="MANUAL">MANUAL</option>
                            <option key="1" value="AUTO">AUTO</option>
                            <option key="2" value="DELETED">DELETED</option>
                        </Input>
                    </>
                )
            default:
                return <Input bsSize="sm" type={this.handleType(value.type)} value={value.value} onChange={(e) => this.handleChange(e.target.value, value.key)} />
        }   
    }

    updateCompany(value, field){
        let temp = this.state.company
        
        for(const i in temp){
            if(temp[i].key == field){
                temp[i].value = value
            }
        }
        
        this.setState({ company: temp })
    }

    handleSubmit(){
        this.setState({loading: true})
        let payload = {}
        for(const i in this.state.company){
            payload[this.state.company[i].key] = this.state.company[i].value
        }
        console.log(payload)
        console.log(payload["status"])
        if(payload["status"]){
            switch(payload["status"]){
                case "MANUAL":
                    payload["status"] = 0;
                    break
                case "AUTO":
                    payload["status"] = 1;
                    break
                case "DELETED":
                    payload["status"] = 2;
                    break
            }
        }
        axios.post(`${config.baseUrl}/superadmin/companies/` + this.props.id , payload, axiosConfig)
        .then(result => {
            this.setState({company: this.props.company})
            this.getCountries()
            this.getTimezones()
            this.setState({success: "Information udpated successfully", loading: false})
            setTimeout(() => { this.setState({ success: false }) }, 2000);
        })
        .catch(error => {
            this.setState({error: error.toString(), loading: false})
        });
    }
    
    render() {
        return (
            <Card>
                <CardBody>
                    {this.state.error ? 
                        <Alert color="danger">
                            <h6 className="mb-1 alert-heading">
                            Error
                            </h6>
                            {this.state.error}
                        </Alert>
                    : ""}
                    {this.state.success ? 
                        <Alert color="success">
                            <h6 className="mb-1 alert-heading">
                            Success
                            </h6>
                            {this.state.success}
                        </Alert>
                    : ""}
                    <div className="d-flex justify-content-end mb-3">
                        <Button disabled={this.state.loading} color="primary" onClick={(e) => this.handleSubmit()}>
                            {this.state.loading ?
                                <span>
                                    <i className="fa fa-spinner fa-spin mr-2"/>
                                    Submitting...
                                </span>
                            : 
                                <span>
                                    <i className="fa fa-save mr-2"/>
                                    Save
                                </span>
                            }
                        </Button>
                    </div>
                    {this.state.company ? this.state.company.map((value, key) => {
                        return (
                        <>
                            <Row className="my-1">
                                <Col>
                                    {value.name ? value.name : value.key+"*"}
                                </Col>
                                <Col>
                                     {this.CustomInput(value)}
                                </Col>
                            </Row>
                        </>
                        )
                    }):""}
                </CardBody>
            </Card>
        );
    }
}

export default ConfigTable;