import { createSlice } from '@reduxjs/toolkit'

export const importSubscriptionsSlice = createSlice({

    name: 'importSubscriptions',
    initialState: { value: { userModules: {} } },
    reducers: {

        insertModuleToUserSub: (state, { payload }) => {

            const { module, userId } = payload
            state.value.userModules[userId] = [...(state.value.userModules[userId] || []), module]

        },

        removeModuleFromUserSub: (state, { payload }) => {

            const { moduleId: moduleToDelete, userId } = payload

            state.value.userModules[userId] = state.value.userModules[userId].filter(({ moduleId }) => moduleId !== moduleToDelete)

        },

        clearImportingModules: (state, { payload }) => {

            const { userId } = payload
            state.value.userModules[userId] = []

        },

        updateModuleInSubscription: (state, { payload }) => {

            const { moduleId, userId, addModulePriceToSubscription } = payload
            state.value.userModules[userId] ??= []

            const moduleIndex = state.value.userModules[userId].findIndex(({ moduleId: id }) => id === moduleId)

            state.value.userModules[userId][moduleIndex] = {
                ...(state.value.userModules[userId]?.[moduleIndex] || {}),
                addModulePriceToSubscription
            }

        }

    },
})

export const {
    insertModuleToUserSub,
    clearImportingModules,
    removeModuleFromUserSub,
    updateModuleInSubscription
} = importSubscriptionsSlice.actions

export default importSubscriptionsSlice.reducer