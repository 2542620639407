import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import config from '../../../api'
import { useReportAxiosErrors } from '../../interceptors/axiosInterceptor';

import AppLayout from './../../layout/default';
import { RoutedContent } from './../../routes';
import '../../styles/loading.css'

import { I18nPropvider, LOCALES } from '../../i18nProvider';

const basePath = process.env.BASE_PATH || '/';

const AppClient = () => {

    useEffect(() => {
        /**
         * useReportAxiosErrors intercepts any non 2xx Axios code
         * in a response and it save it in the redux state
         */
        const stopReportingAxiosErrors = useReportAxiosErrors()
        return stopReportingAxiosErrors
    }, [])

    function changeLang(LOCALES) {
        localStorage.setItem("locale", LOCALES)
        setReload(!reload)
    }

    const [reload, setReload] = useState();
    if (!localStorage.locale) {
        localStorage.setItem("locale", LOCALES.ENGLISH);
    }

    return (
        <I18nPropvider locale={localStorage.locale}>
            <Router basename={basePath}>
                <AppLayout>
                    <RoutedContent />
                    {/* <button type="button" onClick={() => changeLang(LOCALES.ENGLISH)}>English</button>
                <button type="button" onClick={() => changeLang(LOCALES.FRENCH)}>French</button>
                <button type="button" onClick={() => changeLang(LOCALES.GERMAN)}>German</button> */}
                </AppLayout>
            </Router>
        </I18nPropvider>
    );
}

export default AppClient;