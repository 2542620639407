import React from 'react'

export default function RenderIfRequired({ isActive = false, children }) {

    const [hasBeenActive, setHasBeenActive] = React.useState(false)

    if (isActive && !hasBeenActive) setHasBeenActive(true)
    if (!hasBeenActive) return null

    return children
}