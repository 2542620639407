import { createSlice } from '@reduxjs/toolkit'


export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        value: {
            onboards: {},
        }
    },
    reducers: {
        cleanOnboardsToUpdate: (state) => {
            state.value.onboards = {}
        },
        updateOnboard: (state, { payload }) => {


            const { onboardId, onboardPayload } = payload

            if (!onboardId || !onboardPayload) return

            //The phases are updated throw another action
            delete onboardPayload.phases


            if (state.value.onboards?.[onboardId]?.phasesIds) {
                delete onboardPayload.phasesIds
            }


            state.value.onboards[onboardId] = {
                ...state.value.onboards[onboardId],
                ...onboardPayload,
                //The skipt value tell the services function not to update an already updated element
                dontUpdate: false
            }

        },
        skiptUpdate: (state, { payload: { onboardId } = {} }) => {

            state.value.onboards[onboardId] ??= {}
            state.value.onboards[onboardId].dontUpdate = true

        },
        addPhaseToOnboard: (state, { payload: phase }) => {

            const { phaseId, isNew, onboardId, isNewSaved } = phase

            //Either the phase is new (the user has just created it) or saved (if he wants to add an exiting phase the the onboard)
            if (!onboardId || (!isNew && !isNewSaved)) return

            state.value.onboards[onboardId] ??= { phases: {} }

            state.value.onboards[onboardId].phases ??= {}

            state.value.onboards[onboardId].phases[phaseId] = phase

            state.value.onboards[onboardId].phasesIds ??= []

            state.value.onboards[onboardId].phasesIds.push(phaseId)

        },
        removePhaseFromOnboard: (state, { payload }) => {

            const { phaseId, onboardId } = payload

            if (!onboardId) return

            state.value.onboards[onboardId] ??= { phases: {} }

            const { isNew, isNewSaved } = state.value.onboards[onboardId].phases?.[phaseId] || {}

            if (isNew || isNewSaved) {

                delete state.value.onboards[onboardId].phases[phaseId]
                state.value.onboards[onboardId].phasesIds = state.value.onboards[onboardId].phasesIds?.filter?.(phase => phase !== phaseId)

            } else {
                state.value.onboards[onboardId].phases ??= {}
                state.value.onboards[onboardId].phases[phaseId] ??= {}
                state.value.onboards[onboardId].phases[phaseId].isDeleted = true
            }

        },
        recoverPhaseFromOnboard: (state, { payload }) => {

            const { phaseId, onboardId } = payload

            if (!onboardId) return

            state.value.onboards[onboardId] ??= { phases: {} }
            state.value.onboards[onboardId].phases[phaseId] ??= {}

            state.value.onboards[onboardId].phases[phaseId].isDeleted = false

        },
        //This action expects the phasesIds sorted
        resortPhases: (state, { payload }) => {

            const { onboardId, phasesSorted } = payload

            if (!Array.isArray(phasesSorted)) return

            state.value.onboards[onboardId].phasesIds = phasesSorted

        },
        addOnboard: (state, { payload: onboard }) => {


            const { onboardId } = onboard


            state.value.onboards[onboardId] = {
                phasesIds: [],
                phases: {},
                ...onboard
            }


        },
    },
})

export const {
    updateOnboard,
    addPhaseToOnboard,
    removePhaseFromOnboard,
    recoverPhaseFromOnboard,
    cleanOnboardsToUpdate,
    skiptUpdate,
    resortPhases,
    addOnboard,
} = questionsSlice.actions

export default questionsSlice.reducer