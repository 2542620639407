import { LOCALES } from '../constants';

export default {
  [LOCALES.SPANISH]: {
    '_comment': "=======MAIN SIDEBAR=======",
    'dashboard': 'Panel',
    'orders': 'Órdenes',
    'customers': 'Clientes',
    'drivers': 'Conductores',
    'vehicles': 'Vehículos',
    'settings': 'Ajustes',
    'billing': 'Facturacion',
    'support': 'Pedidos',
    'driver-dashboard': 'Panel de Conductores',
    '_comment2': '==================== LOGIN / REGISTER ====================',
    'Sign In to Dashboard': 'Entrar no Dashboard',
    'Senha': '',
    'Sign in': '',
    'Forgot Password': '',
    'Text Subtitle': 'Lorem ipsum dolor sit amet',
  },
};
