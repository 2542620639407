// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Avatar Variants Definitions */\ndiv.y1GXY8ecYy9nbDqI_WVnk {\n  background: none;\n  border-bottom: none;\n  cursor: pointer;\n  color: #3F4651;\n  text-decoration: none;\n}\ndiv.y1GXY8ecYy9nbDqI_WVnk:hover {\n  color: #17191d;\n  text-decoration: none;\n}", ""]);
// Exports
exports.locals = {
	"header": "y1GXY8ecYy9nbDqI_WVnk"
};
module.exports = exports;
