import React, { useState } from 'react';
import axios from 'axios';
import config from '../../../../api'
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaExchangeAlt } from "react-icons/fa";
import useFromLocalStorage from '../../../hooks/useFromLocalStorage'

let axiosConfig = { headers: { Authorization: localStorage.token } };
import {
    Sidebar,
    UncontrolledTooltip,
} from '../../../components';


const SidebarTopC = (props) => {

    const history = useHistory();
    const companyFromLocalStorage = useFromLocalStorage('company')
    const reactLocation = useLocation();
    let user = undefined
    if (localStorage.user) {
        user = JSON.parse(localStorage.user)
        if (!localStorage.company && user.companies) {
            localStorage.setItem("company", JSON.stringify(user.companies?.[0]))
            localStorage.setItem("status", user.companies?.[0]?.onboardStep)

            axiosConfig.params = { getDetailedCompany: true }

            axios.get(`${config.baseUrl}/clients/` + companyFromLocalStorage?.userCompanyId + '/companies', axiosConfig)
                .then(function (response) {

                    const { companies } = response.data
                    const [company] = companies
                    const isOnAdminMode = sessionStorage.getItem('adminMode') === 'admin' || sessionStorage.getItem('adminMode') === 'sales' || sessionStorage.getItem('adminMode') === 'onboarder' || localStorage.getItem('sudo')

                    localStorage.setItem("detailedCompany", JSON.stringify(company))
                    localStorage.setItem("status", company.onboardStep)

                    if (!isOnAdminMode && company.hasProducts) {
                        history.push(`/companies/${company.companyId}/subscriptions`)
                    } else if (!isOnAdminMode) {
                        history.push("/dashboard/products")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else if (!localStorage.company && !localStorage.driver && user.drivers) {
            localStorage.setItem("driver", JSON.stringify(user.drivers[0]))
            history.push("/sales")
        }
    }

    async function changeCompany(company, e) {
        localStorage.removeItem("driver")
        localStorage.setItem("company", JSON.stringify(company))
        localStorage.setItem("status", company.onboardStep)
        if (company.plan != null) {
            axios.get(`${config.baseUrl}/companies/` + JSON.parse(localStorage.company).userCompanyId, axiosConfig)
                .then(function (response) {
                    localStorage.setItem("detailedCompany", JSON.stringify(response.data.data))
                    localStorage.setItem("status", response.data.data.onboardStep)
                    if (reactLocation.pathname == "/dashboard" && response.data.data.onboardStep > 3) {
                        location.reload()
                    } else {
                        if (reactLocation.pathname == "/wizard" && response.data.data.onboardStep < 3) {
                            location.reload()
                        }
                        else if (response.data.data.onboardStep > 3) {
                            history.push("/dashboard")
                        } else {
                            history.push("/wizard")
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            history.push("/planpicker")
        }
    }

    function changeDriver(company, e) {
        localStorage.removeItem("company")
        localStorage.removeItem("status")
        localStorage.setItem("driver", JSON.stringify(company))
        if (reactLocation.pathname == "/sales") {
            location.reload()
        } else {
            history.push("/sales")
        }
    }

    const userName = useFromLocalStorage('user')?.user?.name
    const companyName = useFromLocalStorage('company')?.name

    const mode = () => {
        if (props.isOnSuper) return "Super Admin"
        if (props.isOnOnboarderMode) return "Onboarder - " + userName || ""
        if (props.isOnSalesMode) return "Sales - " + userName || ""
        if (companyName) return companyName
        return 'Admin'
    }

    return (
        <React.Fragment>
            <Sidebar.HideSlim>
                <Sidebar.Section className="pt-0">
                    {user !== undefined && localStorage.company || localStorage.driver ?
                        <>
                            <Link to="/companies">
                                <div className="d-flex">
                                    <div>
                                        {mode()}
                                    </div>
                                    <div className="ml-auto" id="change_company">
                                        <FaExchangeAlt className="fa" />
                                    </div>
                                </div>
                                <UncontrolledTooltip placement="top" target="change_company">
                                    Change Company
                                </UncontrolledTooltip>
                            </Link>
                        </>
                        : ""}
                </Sidebar.Section>
            </Sidebar.HideSlim>
        </React.Fragment>
    )
}

export { SidebarTopC };
