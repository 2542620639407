import React from 'react'

export default function LoadingCover({ message }) {
    return (
        <div className='w-100 position-absolute rounded p-1 text-center'
            style={{ top: '40%', left: 0, backgroundColor: "hsla(0, 0%, 80%, 0.1)" }}>
            <i className='fa fa-fw fa-warning' /> {message || 'No data'}
        </div>
    )
}

export { LoadingCover };