import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PaymentCard from 'react-credit-cards';
import axios from 'axios';
import config from '../../../../../api'
let axiosConfig = {headers: {Authorization: localStorage.token}};

import {Elements} from '@stripe/react-stripe-js';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { MdDone } from "react-icons/md";
import { RiSeedlingLine } from "react-icons/ri";

import {
    Card,
    Input,
    CardBody,
    Container,
    Row,
    Col,
    CardTitle,
    CardFooter,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Alert
} from '../../../../components';
import { HeaderSub } from '../../../components/HeaderSub';
import {InvoicesTable} from './InvoicesTable'
import {PaymentMethodsTable} from './PaymentMethodsTable'

const SessionByDevice = (props) => (
    <div className={classes['session']}>
        <div className={classes['session__title']}>
            { props.title }
        </div>
        <div className={classes['session__values']}>
            <div className={`${classes['session__percentage']} text-${props.color}`}>
                { props.valuePercent }%
            </div>
            <div className={`${classes['session__value']} text-${props.color}`}>
                { props.value }
            </div>
        </div>
    </div>
);

SessionByDevice.propTypes = {
    title: PropTypes.node,
    color: PropTypes.string,
    valuePercent: PropTypes.string,
    value: PropTypes.string
}

const CardForm = ({companyId, parentState, feedData}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(false)
  
    const handleSubmit = async (event) => {
      // Block native form submission.
      event.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
  
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
  
      // Use your card Element with other Stripe.js APIs
      const {error, token} = await stripe.createToken(
        cardElement
      );
  
      if (error) {
        setError(error.message.toString())
      } else {
        const payload = {
            token: token.id
        }
        axios.post(`${config.baseUrl}/superadmin/companies/`+ companyId +`/paymentMethods`, payload, axiosConfig)
        .then(function (response) {
            feedData()
            parentState({ cardModal: false })
        })
        .catch(function (error) {
            console.log(error)
        });
      }
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <CardBody>
            {error ?
                <p>{error}</p>
            :""}
            <CardElement />
        </CardBody>
        <CardFooter className="text-right">
            <Button type="submit" disabled={!stripe} className="mr-2">
                Add Card
            </Button>
            <Button type="button" onClick={(e) => parentState({ cardModal: false })}>
                Close Modal
            </Button>
        </CardFooter>
      </form>
    );
};

export class BillingTable extends React.Component {
    state = {
        card: {
            cvc: '123',
            expiry: '11/12',
            name: 'Place Holder',
            number: '**** **** **** 1234',
            issuer: "mastercard"
        },
        cardModal: false,
        selectedPlan: {},
        plans: [],
        plansModal: false,
        company: false,
        pms: false
    };

    componentDidMount(){
        this.feedData()
    }

    getPms(){
        console.log(this.props.company.companyId)
        const me = this
        axios.get(`${config.baseUrl}/superadmin/companies/` + this.props.company.companyId + "/paymentMethods", axiosConfig)
        .then(function (response) {
            me.setState({pms: response.data.data})
        })
        .catch(function (error) {
            console.log(error)
            me.setState({error: error.toString()})
        });
    }

    feedData = () => {
        const me = this
        axios.get(`${config.baseUrl}/globals/plans`).then(function (response){ me.setState({plans: response.data.data}) })
        axios.get(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId, axiosConfig)
        .then(function (response) {
            console.log("updating company...")
            me.setState({ company: response.data.data })
            me.getPms()
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    changeParentState = (state) => {
        this.setState(state);
    };

    changePlan(){
        let payload = {
            newPlanId: this.state.selectedPlan.serverPlanId,
            /* maxDrivers:
            pricePerDriver: */
            /* tokenPayment: this.state.card */
            tokenPayment: ""
        }
        axios.post(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId +`/plan/change`, payload, axiosConfig)
        .then(response => {
            this.feedData()
            //this.props.reloadParent
            location.reload()
            this.setState({ plansModal: false })
        })
        .catch(error => {
            console.log(error)
        });
    }

    render() {
        let stripePromise = false
        if(this.state.company){
            stripePromise = loadStripe(this.state.company.stripe.public);
        }
        const selectedPlan = this.state.selectedPlan
        return (
            <React.Fragment>
                {this.state.company ?
                <Container>
                    <Card className="mb-3">
                        <CardBody>
                            <Modal isOpen={this.state.plansModal} centered toggle={(e) => this.setState({ plansModal: !this.state.plansModal })} size="lg">
                                <ModalHeader>
                                    Choose your plan
                                </ModalHeader>
                                <ModalBody>

                                    <Row>
                                        { this.state.plans[0] ? this.state.plans.map((value, key) => {
                                            let differentPlan = true
                                            if(this.props.company.plan && this.props.company.plan.name == value.name){
                                                differentPlan = false
                                            }
                                            return(
                                                <>
                                                {this.props.company && differentPlan  ?
                                                    <Col className="p-1">
                                                        <Card className="mb-3" style={ value.serverPlanId == selectedPlan.serverPlanId ? { border: "1px solid #1EB7FF" } : {cursor: "pointer"}} onClick={(e) => this.setState({ selectedPlan: value })}>
                                                            <CardBody>
                                                                <div  className="text-center">
                                                                    <RiSeedlingLine className="fa-fw fa-3x text-primary "/>
                                                                    <h4 className="text-capitalize mt-0 mb-4 fw-300">{value.name}</h4>
                                                                    <div>
                                                                        <ul class="list-unstyled">
                                                                            <li><MdDone className="fa-fw mr-3 text-primary" />${value.price} per driver</li>
                                                                            <li><MdDone className="fa-fw mr-3 text-primary" />Up to {value.maxDrivers} drivers</li>
                                                                            <li><MdDone className="fa-fw mr-3 text-primary" />Up to {value.maxOrders} orders</li>
                                                                        </ul>
                                                                    </div>
                                                                    <h3 className="text-capitalize mt-4">${value.price} <small>/ driver</small></h3>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                : ""} 
                                                </>
                                            )
                                        }) : "" }
                                    </Row>
                                    
                                </ModalBody>
                                <ModalFooter>
                                    {selectedPlan.serverPlanId ? 
                                        <Button onClick={(e) => this.changePlan()}>Subscribe</Button>
                                    : ""}
                                    <Button onClick={(e) => this.setState({ plansModal: !this.state.plansModal })}>Close</Button>
                                </ModalFooter>
                            </Modal>
                            <HeaderSub
                                title="My plan"
                                subTitle=""
                            />
                            <div>
                                <dl className="row">
                                    <span className="col">
                                        <dt>Plan</dt>
                                        <dd>{this.state.company.plan ? this.state.company.plan.name : "Not specified"}</dd>
                                    </span>
                                    <span className="col">
                                        <dt className="d-block">Address</dt>
                                        <dd className="d-block">{this.state.company.address ? this.state.company.address : "Not specified"}</dd>
                                    </span>
                                    <span className="col">
                                        <dt className="d-block">Mobile</dt>
                                        <dd className="d-block">{this.state.company.phone ? this.state.company.phone : "Not specified"}</dd>
                                    </span>
                                    <span className="col">
                                        <dt className="d-block">Email</dt>
                                        <dd className="d-block">
                                            <a href="#">{this.state.company.email ? this.state.company.email : "Not specified"}</a>
                                        </dd>
                                    </span>
                                </dl>
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button type="button" onClick={(e) => this.setState({plansModal: !this.state.plansModal})}>Change Plan</Button>
                            </div>
                        </CardBody>
                        <CardFooter className="small">
                            <i className="fa fa-fw fa-support text-muted mr-2"></i>
                            If you have trouble with the configuration, you can contact us. <a href="https://sprwt.freshdesk.com/support/solutions/43000368664">We Can Help</a>
                        </CardFooter>
                    </Card>
                    <Card className="mb-3">
                        <Modal isOpen={this.state.cardModal} centered toggle={(e) => this.setState({ cardModal: !this.state.cardModal })} size="lg">
                            <ModalHeader>
                                <h6>Please fill out your card information</h6>
                            </ModalHeader>
                            {stripePromise ? 
                                <Elements stripe={stripePromise}>
                                    <CardForm feedData={this.feedData} companyId={this.props.company.companyId} parentState={this.changeParentState} />
                                </Elements>
                            : ""}
                        </Modal>
                        <CardBody>
                            <CardTitle tag="h6" className="mb-0 mt-4">
                                <HeaderSub
                                    title="My cards"
                                    subTitle=""
                                />
                            </CardTitle>
                            {this.state.pms.length > 0 ? 
                                <Table className="mb-0" hover responsive>
                                    <thead>
                                        <tr>
                                            {/* <th className="bt-0">Active</th> */}
                                            <th className="bt-0">Issuer</th>
                                            <th className="bt-0">Number</th>
                                            <th className="bt-0">Expiring</th>
                                            <th className="bt-0">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <PaymentMethodsTable reloadParent={this.feedData} company={this.state.company} pms={this.state.pms} /> 
                                    </tbody>
                                </Table>
                            : "You have no cards associated with this account."}
                            <div className="d-flex justify-content-end mt-2">
                                <Button onClick={(e) => this.setState({ cardModal: !this.state.cardModal })}>Add Card</Button>
                            </div>
                        </CardBody>
                        <CardFooter className="small">
                            <i className="fa fa-fw fa-support text-muted mr-2"></i>
                            If you have trouble with the configuration, you can contact us. <a href="https://sprwt.freshdesk.com/support/solutions/43000368664">We Can Help</a>
                        </CardFooter>
                    </Card>
                    {/* <Card className="mb-3">
                        <CardBody>
                            <CardTitle tag="h6" className="mb-0">
                            <HeaderSub
                            title="Billing History "
                            subTitle=""
                            />
                            </CardTitle>
                            <InvoicesTable />
                        </CardBody>
                    </Card> */}
                </Container>
            : <h3 className="fw-300"><i className="fa fa-spinner fa-spin"/> Loading </h3>}
            </React.Fragment>
        );
    }
}