import { LOCALES } from '../constants';

export default {
  [LOCALES.SPANISH]: {
    'edit-file': 'Editar {file} e salvar.',
    'hello': 'Olá',
    'demo-placeholder': 'Placeholder',
    '_comment': "=======MAIN SIDEBAR=======",
    'dashboard': 'Painel',
    'orders': 'Ordens',
    'customers': 'Clientes',
    'drivers': 'Condutores',
    'vehicles': 'Veiculos',
    'settings': 'Preferencias',
    'billing': 'Vendas',
    'support': 'Ajuda',
    'driver-dashboard': 'Painel Condutor',
    '_comment2': '==================== LOGIN / REGISTER ====================',
    'Login no Dashboard': 'Login no Dashboard',
    'Senha': 'Senha',
    'Sign in': 'Sign in',
    'Forgot Password': 'Esqueceu Password',
    'Text Subtitle': 'Lorem ipsum dolor sit amet',
  },
};
