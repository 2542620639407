// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3YTVohtrYpKRAjDbBVNQaA {\n  height: 0.3rem;\n}", ""]);
// Exports
exports.locals = {
	"slim": "_3YTVohtrYpKRAjDbBVNQaA"
};
module.exports = exports;
