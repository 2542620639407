import { createSlice } from '@reduxjs/toolkit'


export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        value: {
            questions: {},
        }
    },
    reducers: {
        updateQuestion: (state, { payload }) => {

            const { questionId, questionPayload } = payload

            if (!questionId || !questionPayload) return

            state.value.questions[questionId] = {
                ...state.value.questions[questionId],
                ...questionPayload,
                dontUpdate: false
            }

        },
        skiptUpdate: (state, { payload: { questionId } = {} }) => {

            state.value.questions[questionId] ??= {}

            state.value.questions[questionId].dontUpdate = true

        },
        addQuestionsToUpdate: (state, { payload }) => {
            state.value = [...state.value, ...payload]
        },
        cleanQuestionsToUpdate: (state) => {
            state.value.questions = {}
        },
        closeAddPhasesModal: (state) => {
            state.value.openAddPhasesModal = false
        },
        setQuestionDetailsToShow: (state, { payload = {} }) => {
            state.value.questionWhichDetailsToShow = payload.questionId
            state.value.questionLinkToFocus = payload.questionLinkToFocus
        },
    },
})

export const {
    addQuestionsToUpdate,
    cleanQuestionsToUpdate,
    updateQuestion,
    skiptUpdate,
    setQuestionDetailsToShow,
} = questionsSlice.actions

export default questionsSlice.reducer