import React, { useState } from 'react'

export default function EditableText({
    text,
    defaultValue,
    onChange,
    placeholder = 'Edit field',
    width = '200px',
    height,
    textClassName,
    inputClassName,
    stopPropagation = true,
    editable = true
}) {

    const [editName, setEditName] = useState(false)

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === "Escape") {
            event.target.blur();
        }
    }

    const nameInput = <input
        style={{ width, height }}
        defaultValue={defaultValue || undefined}
        onBlur={() => { setEditName(false) }}
        autoFocus
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={handleInputKeyDown}
        className={inputClassName}
        onClick={(ev) => ev.stopPropagation()} ></input>


    const nameSpan = <span
        className={textClassName}
        onClick={(ev) => {
            if (stopPropagation) ev.stopPropagation()
            if (editable) setEditName(true)
        }} >
        {text}
    </span>

    return editName ? nameInput : nameSpan
}