import React from 'react';
// import { LanguageSelect } from '../../routes/components/LanguageSelect';
import { SidebarBottomB } from '../../routes/components/Sidebar/SidebarBottomB';
import { SidebarTopB } from '../../routes/components/Sidebar/SidebarTopB';
import { SidebarTopC } from '../../routes/components/Sidebar/SidebarTopC';
import {
    Sidebar,
    SidebarTrigger
} from './../../components';
import { SidebarAdminNav } from './SidebarAdminNav';
import { SidebarSalesNav } from './SidebarSalesNav';
import { SidebarSuperNav } from './SidebarSuperNav';
import { SidebarWizardNav } from './SidebarWizardNav';



export const SidebarASidebar = (props) => {
    let isOnSuper = props.history.location.pathname.includes("/superAdmin") || props.history.location.pathname.includes("/superadmin")
    return (
        <Sidebar>
            { /* START SIDEBAR-OVERLAY: Close (x) */ }
            <Sidebar.Close>
                <SidebarTrigger tag={ 'a' } href="#">
                    <i className="fa fa-times-circle fa-fw"></i>
                </SidebarTrigger>
            </Sidebar.Close>
            { /* END SIDEBAR-OVERLAY: Close (x) */ }

            { /* START SIDEBAR: Fixed Section */ }
            <Sidebar.Section>
                <SidebarTopB />
            </Sidebar.Section>
            
            { /* END SIDEBAR: Fixed Section */ }

            { /* START SIDEBAR: Mobile Scroll Wrapper */ }
            <Sidebar.MobileFluid>
                { /* START SIDEBAR: Everywhere */ }
                <Sidebar.Section fluid cover>
                    <SidebarTopC isOnSuper={isOnSuper} />
                    {localStorage.company && localStorage.status > 3 && !isOnSuper ?
                        <SidebarAdminNav />
                    : 
                    localStorage.company && !isOnSuper ? 
                        <SidebarWizardNav /> 
                    :
                        "" 
                    }
                    {localStorage.driver && !isOnSuper ?
                        <SidebarSalesNav />
                    : ""}
                    {localStorage.sudo && isOnSuper ?
                        <SidebarSuperNav />
                    : ""}
                </Sidebar.Section>
                { /* END SIDEBAR: Everywhere */ }
                <SidebarBottomB />
                {/* <LanguageSelect /> */}
                
            </Sidebar.MobileFluid>
            { /* END SIDEBAR: Mobile Scroll Wrapper */ }

        </Sidebar>
    )
};
