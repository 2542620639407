import React, { useEffect } from 'react';
import { SidebarMenu } from './../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUser, faCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import { faEllipsis, faBarsProgress, faTable } from '@fortawesome/free-solid-svg-icons'
import { RiDashboardLine } from 'react-icons/ri'
import { useSelector } from 'react-redux';
import useFromLocalStorage from '../../hooks/useFromLocalStorage';
import usePlans from '../../routes/Dashboards/ActivePlans/hooks/usePlans';

export const SidebarAdminNav = () => {

    const user = useFromLocalStorage('user')?.user || {};
    const company = useFromLocalStorage('user')?.companies?.[0]?.companyName || '';
    const companyData = useFromLocalStorage('company') || '';
    const userCompanyId = companyData?.userCompanyId;

    const [plans, loadingPlans, noPlans, updatePlans] = usePlans(userCompanyId)

    let companyId;
    if (companyData) {
        companyId = companyData.companyId;
    }

    const {
        email: userEmail,
        name: userName,
        phone: phone,

    } = user



    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "f265c86d-47f0-47bd-a9e2-381058ff9349";

        (function () {
            var d = document;
            var s = d.createElement("script");

            s.src = "https://client.crisp.chat/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();

        if (userEmail) $crisp.push(["set", "user:email", userEmail]);
        if (userName) $crisp.push(["set", "user:nickname", userName]);
        if (phone) $crisp.push(["set", "user:phone", phone]);
        if (company) $crisp.push(["set", "user:company", company]);

    }, [])

    const isSubscriptionInactive = useSelector(state => state.errors.value?.includes?.('inactive_subscription'))

    const openChat = () => {
        $crisp?.push(["do", "chat:open"]);
    };

    if (isSubscriptionInactive) return (
        <>
            <SidebarMenu className="menu_icon_size">
                <SidebarMenu.Item
                    icon={<FontAwesomeIcon className='fa fa-fw'
                        style={{
                            marginRight: '1.5rem',
                            height: "27px"
                        }}
                        icon={faEnvelope} />}
                    title='Products'
                    to='/dashboard/products'
                    exact
                ></SidebarMenu.Item>
                <SidebarMenu.Item
                    icon={<FontAwesomeIcon className='fa fa-fw'
                        style={{
                            marginRight: '1.5rem',
                            height: "27px"
                        }}
                        icon={faUser} />}
                    title="Profile"
                    to='/dashboard/profile'
                    exact
                ></SidebarMenu.Item>
            </SidebarMenu>
        </>
    )

    return (
        <>
            <SidebarMenu className="menu_icon_size" >


                {
                    (companyId !== undefined) && !noPlans ?

                        <SidebarMenu.Item
                            icon={<i><RiDashboardLine className="fa-fw" size={24} /></i>}
                            title="Dashboard"
                            to={`/companies/${companyId}/subscriptions`}
                            exact
                        ></SidebarMenu.Item>
                        // RETURN FRAGMENT TO PREVENT NULL ERROR IF !companyId
                        : <></>
                }


                <SidebarMenu.Item
                    icon={<FontAwesomeIcon className='fa fa-fw'
                        style={{
                            marginRight: '1.5rem',
                            height: "27px"
                        }}
                        icon={faEnvelope} />}
                    title='Products'
                    to='/dashboard/products'
                    exact
                ></SidebarMenu.Item>
                <SidebarMenu.Item
                    icon={<FontAwesomeIcon className='fa fa-fw'
                        style={{
                            marginRight: '1.5rem',
                            height: "27px"
                        }}
                        icon={faUser} />}
                    title="Profile"
                    to='/dashboard/profile'
                    exact
                ></SidebarMenu.Item>
                <SidebarMenu.Item
                    icon={<i className='fa fa-fw fa-users' />}
                    title="Admins"
                    to='/dashboard/admins'
                    exact
                ></SidebarMenu.Item>
                <SidebarMenu.Item
                    icon={<FontAwesomeIcon className='fa fa-fw'
                        style={{
                            marginRight: '1.5rem',
                            height: "27px"
                        }}
                        icon={faCircleQuestion} />}
                    title="Help"
                >
                    <SidebarMenu.Item
                        title='Chat with us'
                        onClick={() => openChat()}
                        exact />
                    <SidebarMenu.Item
                        title='Leave Feedback'
                        href='https://feedback.sprwt.io'
                        exact
                    />
                    <SidebarMenu.Item
                        title='Training Videos'
                        href=' https://www.youtube.com/@Sprwt/videos'
                        exact
                    />
                    <SidebarMenu.Item
                        title='Sprwt Knowledgebase'
                        href='https://sprwt.freshdesk.com'
                        exact
                    />

                </SidebarMenu.Item>
            </SidebarMenu>
        </>
    );
}
