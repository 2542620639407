import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';
import config from "../../../../api";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { RiSeedlingLine } from "react-icons/ri";
import { MdDone } from "react-icons/md";

import {
    TinyDonutChart
} from "./components/TinyDonutChart"

import { 
    Container,
    Row,
    Col,
    Card,
    CardBody,
    TabPane,
    UncontrolledTabs,
    Nav,
    NavItem,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    CustomInput,
    Alert,
    Media
} from '../../../components';

import { ConfigTable } from "./components/ConfigTable";
import { BillingTable } from "./components/BillingTable";
import { UsersTable } from "./components/UsersTable";
import { DriversTable } from "./components/DriversTable";

let axiosConfig = {
    headers: {
        Authorization: localStorage.token
    }
};

const fields = {
    /* example: key: name */
    "companyId": "Company ID //",
    "name": "Company Name",
    "maxDrivers": "Drivers Limit//",
    "maxOrders": "Orders Limit (Plan)",
    "timezone": "Company Time Zone",
    "picture": "Company Logo",
    "email": "Company Email",
    "phone": "Company Phone",
    "address": "Company Address",
    "zipCode": "Company Zip Code",
    "state": "Company State",
    "country": "Company Country",
    "driverMode": "Zone Settings",
    "distanceMeasure": "Measure unit",
    "defaultOrderStatusId": "First Order Status //",
    "smsBalance": "SMS Balance //",
    "status": "Company Status",
    "totalOrders": "Orders Limit //",
    "totalOrdersMonth": "Orders (per Month)",
    "maxOrders": "Maximum Orders (limit)",
    "sms_credits_by_message": "SMS Fee",
    /* "defaultOrderStatusId": "Default Order Status" */
}

const excludedFields = [
    "totalOrdersMonth",
    "companyId",
    "location",
    "maxDrivers",
    "smsBalance",
    "totalOrders",
    "onboardStep",
    "totalDrivers",
    "totalUsers",
    "stripe",
    "google",
    "plan",
    "maxOrders",
    "picture",
    "lastRenew",
    "defaultOrderStatusId"
]

export class PurchaseModal extends React.Component {
    state = {
        selectedCard: null,
        quantity: 0
    };

    /* componentDidMount(){
        let card = this.props.cards[0]
        if(card){
            this.setState({selectedCard: {
                expiry: card.card.exp_month + "/" + card.card.exp_year,
                number: this.formatCard(card.card),
                issuer: card.card.brand,
                name: " ",
                stripeCardId: card.stripeCardId 
            }})
        } else {
            this.setState({error: "Please add a card to your account first"})
        }
    } */

    /* formatCard(card){
        switch(card.brand){
            case "visa": 
                return "**** **** **** " + card.last4
            case "amex":
                return "**** ****** *" + card.last4
            default:
                return "**** **** **** " + card.last4
        }
    } */

    /* setActiveCard(id){
        for(const i in this.props.cards){
            if(this.props.cards[i].stripeCardId == id){
                const card = this.props.cards[i].card
                this.setState({selectedCard: {
                    expiry: card.exp_month + "/" + card.exp_year,
                    number: this.formatCard(card),
                    issuer: card.brand,
                    name: " ",
                    stripeCardId: this.props.cards[i].stripeCardId 
                }})
            }
        }
    } */

    submitPayment(type){
        console.log(type=="SMS") 
        switch(type){
            case "Orders":
                //endpoint for Orders
                if(this.state.quantity >= 1){
                    let payload = {
                        /* newPlanId: this.state.selectedPlan.serverPlanId, */
                        /* pricePerDriver: */
                        maxOrders: this.state.quantity,
                        /* maxDrivers: */
                        tokenPayment: "" /* this.state.selectedCard.stripeCardId */
                    }
                    axios.post(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId +`/plan/change`, payload, axiosConfig)
                    .then(response => {
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error)
                    });
                } else {
                    this.setState({error: "Minimum purchase amount: 1"})
                }
                break;
            case "Drivers":
                //endpoint for Drivers
                if(this.state.quantity >= 0){
                    let payload = {
                        /* newPlanId: this.state.selectedPlan.serverPlanId, */
                        /* pricePerDriver: */
                        /* maxOrders: */
                        maxDrivers: this.state.quantity,
                        tokenPayment: ""
                        /* tokenPayment: this.state.selectedCard.stripeCardId */
                    }
                    axios.post(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId +`/plan/change`, payload, axiosConfig)
                    .then(response => {
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error)
                    });
                } else {
                    this.setState({error: "Minimum purchase amount: 0"})
                }
                break;
            case "PricePerDriver":
                if(this.state.quantity >= 0){
                    let payload = {
                        /* newPlanId: this.state.selectedPlan.serverPlanId, */
                        /* maxDrivers: this.state.quantity, */
                        /* maxOrders: */
                        pricePerDriver: this.state.quantity,
                        tokenPayment: ""
                        /* tokenPayment: this.state.selectedCard.stripeCardId */
                    }
                    axios.post(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId +`/plan/change`, payload, axiosConfig)
                    .then(response => {
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error)
                    });
                } else {
                    this.setState({error: "Minimum price: 0"})
                }
                break;
            case "SMS":
                //endpoint for SMS
                console.log("PASSED SMS")
                if(this.state.quantity >= 50){
                    
                    let payload={
                        value: this.state.quantity,
                        /* token: this.state.selectedCard.stripeCardId */
                    }
                    axios.post(`${config.baseUrl}/superadmin/companies/`+ this.props.company.companyId +`/sms/add`, payload, axiosConfig)
                    .then(response => {
                        console.log(response.data.data)
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    
                } else {
                    this.setState({error: "Minimum purchase amount: $50.00"})
                }
                break;
        }
    }

    render(){
        return(
            <>
                <ModalHeader>
                    Update {this.props.type} Quantity
                </ModalHeader>
                <ModalBody>
                    {this.state.error ? 
                        <Alert color="danger">
                            <h6 className="mb-1 alert-heading">
                            Error
                            </h6>
                            {this.state.error}
                        </Alert>
                    : ""}
                    <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                            <div className="d-block w-100">
                                Quantity:
                                <input step={this.props.type == "SMS" ? ".01" : "1"} onChange={(e) => this.setState({quantity: e.target.value})} type="number" value={this.state.quantity} />
                            </div>
                            {/* {this.props.cards ?
                                <div className="d-block w-100">
                                    Choose your card:
                                    <Input type="select" onChange={(e) => this.setActiveCard(e.target.value)}>
                                        {this.props.cards.map((value, key) => {
                                            return(
                                                <option key={key} value={value.stripeCardId}>**** **** **** {value.card.last4} - {value.card.exp_month}/{value.card.exp_year}</option>
                                            )
                                        })}
                                    </Input>
                                </div>
                            : ""} */}
                        </Col>
                        {/* <Col>
                            <PaymentCard
                                expiry={this.state.selectedCard.expiry}
                                number={this.state.selectedCard.number}
                                preview={true}
                                issuer={this.state.selectedCard.issuer}
                                name={this.state.selectedCard.name}
                            />
                        </Col> */}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.submitPayment(this.props.type)}>Confirm</Button>
                    <Button onClick={this.props.closeModal}>Close</Button>
                </ModalFooter>
            </>
        )
    }
}



const CompanyDetails = (props) => {
    const history = useHistory();
    const [companyDetails, setCompanyDetails] = useState(false)
    const [company, setCompany] = useState(false)
    const [error, setError] = useState(false)
    const [cards, setCards] = useState(false)
    const [modal, setModal] = useState("")
    const [plans, setPlans] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(false)
    const [plansModal, setPlansModal] = useState(false)
    const [period, setPeriod] = useState("monthly")

    useEffect(() => {
        getData()
    }, []);

    function getData(){
        getCompany()
        getPlans()
        getCards()
    }

    function getCompany(){
        axios.get(`${config.baseUrl}/superadmin/companies/` + props.match.params.id, axiosConfig)
        .then(function (response) {
            setCompanyDetails(response.data.data)
            let temp = []
            for (const [key, value] of Object.entries(response.data.data)) {
                let type = typeof(value)
                let name = fields[key]
                if(typeof(value)!="array" && !excludedFields.includes(key)){
                    temp.push({key, name, value, type})
                }
            }
            setCompany(temp)
        })
        .catch(function (error) {
            console.log(error)
            setError(error)
        });
    }

    function getPlans(){
        axios.get(`${config.baseUrl}/globals/plans`).then(function (response){ setPlans(response.data.data) })
    }

    function getCards(){
        axios.get(`${config.baseUrl}/superadmin/companies/` + props.match.params.id + "/paymentMethods", axiosConfig)
        .then(response => {
            setCards(response.data.data)
        })
        .catch(error => {
            console.log(error)
            setError(error.toString())
        });
    }

    function changePlan(period){
        let payload = {
            newPlanId: selectedPlan.serverPlanId,
            tokenPayment: "",
            prices: period
        }
        axios.post(`${config.baseUrl}/superadmin/companies/`+ companyDetails.companyId +`/plan/change`, payload, axiosConfig)
        .then(response => {
            getData()
            setPlansModal(false)
        })
        .catch(error => {
            setError(JSON.stringify(error))
        });
    }

    function closeModal(){
        getCompany()
        setModal("")
    }

    function upload(picture){
        if(picture.target.files[0]){
            var formData = new FormData();
            formData.append("file", picture.target.files[0]);
            axios.post(`${config.baseUrl}/superadmin/companies/`+ props.match.params.id + `/picture`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.token
                }
            }).then(response => {
                console.log(response)
                getCompany()
            })
            .catch(error => {
                console.log(error)
            });
        }
    }

    function isPaid(expDate) {
        const now = new Date()
        const exp = new Date(expDate)

        const diffTime = then - now

        if(diff.hours < 2){
            return Math.ceil((diff.minutes*100)/43200)
        }
    }

    function percentCalc(lastRenewal, expireDate) {
        const now = new Date()
        const then = new Date(expireDate)
        const diffTime = then - now
        
        const last = new Date(lastRenewal)
        const subscription_duration = then - last

        const diff = {
            days: Math.ceil(diffTime / (1000 * 60 * 60 * 24)),
            hours: Math.ceil(diffTime / (1000 * 60 * 60)),
            minutes: Math.ceil(diffTime / (1000 * 60))
        }

        const diffSubscription = {
            days: Math.ceil(subscription_duration / (1000 * 60 * 60 * 24)),
            hours: Math.ceil(subscription_duration / (1000 * 60 * 60)),
            minutes: Math.ceil(subscription_duration / (1000 * 60))
        }

        console.log("diffSubscription«««««««««««", diffSubscription)

        if(diff.hours < 2){
            return Math.ceil((diff.minutes*100)/diffSubscription.minutes)
        }
        else if(diff.days < 2){
            return Math.ceil((diff.hours*100)/diffSubscription.hours)
        } else {
            return Math.ceil((diff.days*100)/diffSubscription.days) //isto está achanatado
        }
    }

    function expireCalc(expireDate) {
        const now = new Date()
        const then = new Date(expireDate) 
        const diffTime = then - now

        const diff = {
            days: Math.ceil(diffTime / (1000 * 60 * 60 * 24)),
            hours: Math.ceil(diffTime / (1000 * 60 * 60)),
            minutes: Math.ceil(diffTime / (1000 * 60))
        }

        if(diff.hours < 2){
            return diff.minutes + " minutes"
        }
        else if(diff.days < 2){
            return diff.hours + " hours"
        } else {
            return diff.days + " days"
        }
    }

    return (
        <React.Fragment>
            {company ? 
            <Container>
                <div className=" d-flex justify-content-between align-items-center">
                    <div className="d-flex mb-5 mt-4">
                        <h1 className="display-4 mr-3 mb-0 align-self-start">
                            <Link to="/superadmin"><IoIosArrowRoundBack /></Link>
                            {companyDetails.name}
                        </h1>
                    </div>
                    {/* <Button outline color="secondary">
                        <i className="fa fa-external-link mr-2"/>
                            Login as {companyDetails.name}
                    </Button> */}
                </div>
                {error ? error : ""}
                <Row>
                    {companyDetails.plan ? 
                        <>
                        <Col lg="12">
                            {new Date() < new Date(companyDetails.plan.expireAt) ? 
                            <Alert color="primary">
                                <Media>
                                    <Media left middle className="mr-2">
                                        <TinyDonutChart 
                                            percentage={percentCalc(companyDetails.lastRenew.date, companyDetails.plan.expireAt)}
                                            data={[{name: 'Group A', value: percentCalc(companyDetails.lastRenew.date, companyDetails.plan.expireAt)},{name: 'Group B', value: (100-percentCalc(companyDetails.lastRenew.date, companyDetails.plan.expireAt))}]}
                                        />
                                    </Media>
                                    <Media body>
                                        <div className="d-lg-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1 alert-heading">
                                                    Plan Renewal
                                                </h6> 
                                                Plan will be renewed in {expireCalc(companyDetails.plan.expireAt)} ({companyDetails.plan.expireAt})
                                            </div>
                                            {/* <div>
                                                <Button color="primary">Renew Subscription</Button>{' '}
                                                <Button color="danger" outline>Cancel</Button>{' '}
                                            </div> */}
                                        </div>
                                    </Media>
                                </Media>
                            </Alert>
                            : 
                            <>
                            <Alert color="danger">
                                <Media>
                                    <Media left middle className="mr-2">
                                        <i className="fa fa-close fa-fw fa-2x alert-icon"></i> 
                                    </Media>
                                    <Media body>
                                        <div className="d-lg-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1 alert-heading">
                                                    Failed Plan Renewal
                                                </h6> 
                                                Plan expired on {companyDetails.plan.expireAt}
                                            </div>
                                            {/* <div>
                                                <Button color="primary">Renew Subscription</Button>{' '}
                                                <Button color="danger" outline>Cancel</Button>{' '}
                                            </div> */}
                                        </div>
                                    </Media>
                                </Media>
                            </Alert>
                            {/* <Alert color="warning">
                                <Media>
                                    <Media left middle className="mr-2">
                                        <i className="fa fa-warning fa-fw fa-2x alert-icon"></i> 
                                    </Media>
                                    <Media body>
                                        <div className="d-lg-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1 alert-heading">
                                                    Failed Atempt on Plan Renewal
                                                </h6> 
                                                Atempted on plan renewal on {companyDetails.plan.expireAt}
                                            </div>
                                            <div>
                                                <Button color="primary">Renew Subscription</Button>{' '}
                                                <Button color="danger" outline>Cancel</Button>{' '}
                                            </div>
                                        </div>
                                    </Media>
                                </Media>
                            </Alert> */}
                            </>
                            }
                            
                        </Col>
                        </>
                    :""}
                    <Col lg={4} className="mb-3">

                    <Modal isOpen={plansModal} centered toggle={(e) => setPlansModal(!plansModal)} size="lg">
                        <ModalHeader>
                            Choose your plan
                        </ModalHeader>
                        <ModalBody>

                            <Row>
                                { plans && plans[0] ? plans.map((value, key) => {
                                    let differentPlan = true
                                    if(companyDetails.plan && companyDetails.plan.name == value.name){
                                        differentPlan = false
                                    }
                                    return(
                                        <>
                                            {companyDetails && differentPlan  ?
                                                <Col className="p-1">
                                                    <Card className="mb-3" style={ value.serverPlanId == selectedPlan.serverPlanId ? { border: "1px solid #1EB7FF" } : {cursor: "pointer"}} onClick={(e) => setSelectedPlan(value)}>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                <RiSeedlingLine className="fa-fw fa-3x text-primary "/>
                                                                <h4 className="text-capitalize mt-0 mb-4 fw-300">{value.name}</h4>
                                                                <div>
                                                                    <ul class="list-unstyled">
                                                                        <li><MdDone className="fa-fw mr-3 text-primary" />${value.price} per driver</li>
                                                                        <li><MdDone className="fa-fw mr-3 text-primary" />Up to {value.maxDrivers} drivers</li>
                                                                        <li><MdDone className="fa-fw mr-3 text-primary" />Up to {value.maxOrders} orders</li>
                                                                    </ul>
                                                                </div>
                                                                <h3 className="text-capitalize mt-4">${value.price} <small>/ driver</small></h3>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            : ""} 
                                        </>
                                    )
                                }) : "" }
                                {/* {selectedPlan ?
                                    <>
                                        <InputGroup className="px-2">
                                            <InputGroupAddon addonType="prepend">Subscription Interval</InputGroupAddon>
                                            <Input 
                                                type="select" 
                                                name="period" 
                                                id="period" 
                                                value={period}
                                                onChange={(e) => setPeriod(e.target.value)}
                                            >
                                                <option key="monthly" value="monthly">Monthly</option>
                                                <option key="yearly" value="yearly">Yearly</option>
                                            </Input>
                                        </InputGroup>
                                    </>
                                : ""} */}
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {selectedPlan && selectedPlan.serverPlanId ? 
                                <Button onClick={(e) => changePlan(period)}>Subscribe</Button>
                            : ""}
                            <Button onClick={(e) => setPlansModal(!plansModal)}>Close</Button>
                        </ModalFooter>
                    </Modal>
                   
                    <Card className="h-100" type="background" color="primary">
                        <CardBody className="text-white">
                        {companyDetails.plan ?
                            <>
                                <div className="d-flex" style={{cursor: "pointer"}} onClick={(e) => setPlansModal(true)}>
                                    <h2 className="text-white mr-3 mb-0 align-self-start">
                                        ${companyDetails.plan.priceTotal} <small>/{companyDetails.plan.priceBy}</small>
                                    </h2>
                                </div>
                                <div className="text-left">
                                        <Modal isOpen={modal=="PricePerDriver"} centered toggle={(e) => setModal("")} size="lg">
                                            <PurchaseModal company={companyDetails} cards={cards} type="PricePerDriver" closeModal={() => closeModal("PricePerDriver")} />
                                        </Modal>
                                    <div style={{cursor: "pointer"}} onClick={(e) => setModal("PricePerDriver")} className="mr-2 text-white text-capitalize">{companyDetails.plan.name} (${companyDetails.plan.pricePerDriver} per driver)</div>
                                </div>
                            </>
                            :
                            <div className="d-flex" onClick={(e) => setPlansModal(true)} style={{cursor: "pointer"}}>
                                <h2 className="text-white mr-3 mb-0 align-self-start">
                                    No Plan
                                </h2>
                            </div>
                            }
                        </CardBody>
                    </Card>
                    
                        
                    </Col>
                    <Col lg={8} className="mb-3">
                        <Card className="h-100">
                            <CardBody>
                                <Row>
                                    <div className="col border-right">
                                        <Modal isOpen={modal=="orders"} centered toggle={(e) => setModal("")} size="lg">
                                            <PurchaseModal company={companyDetails} cards={cards} type="Orders" closeModal={() => closeModal("orders")} />
                                        </Modal>
                                        <span className="btn btn-link"  style={{ cursor: "pointer" }} onClick={(e) => setModal("orders")}>
                                            <div>
                                                <div className="">
                                                    <span className="text-muted">Orders</span>
                                                </div>
                                                <div className="text-left">
                                                    <span className="mr-2 text-inverse"><i className="fa fa-fw fa-cube mr-2"></i>{companyDetails.totalOrdersMonth} <small>({companyDetails.maxOrders})</small> Orders</span>
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="col border-right">
                                        <Modal isOpen={modal=="drivers"} centered toggle={(e) => setModal("")} size="lg">
                                            <PurchaseModal company={companyDetails} cards={cards} type="Drivers" closeModal={() => closeModal("drivers")} />
                                        </Modal>
                                        <a className="btn btn-link" style={{ cursor: "pointer" }} onClick={(e) => setModal("drivers")}>
                                            <div>
                                                <div className="">
                                                    <span className="text-muted">Drivers</span>
                                                </div>
                                                <div className="text-left">
                                                    <a href="#" className="mr-2"><i className="fa fa-fw fa-user mr-2"></i>{companyDetails.totalDrivers} <small>({companyDetails.maxDrivers})</small> Drivers</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <Modal isOpen={modal=="sms"} centered toggle={(e) => setModal("")} size="lg">
                                            <PurchaseModal company={companyDetails} cards={cards} type="SMS" closeModal={() => closeModal("sms")} />
                                        </Modal>
                                        <a className="btn btn-link" style={{ cursor: "pointer" }} onClick={(e) => setModal("sms")}>
                                            <div>
                                                <div className="">
                                                    <span className="text-muted">SMS</span>
                                                </div>
                                                <div className="text-left">
                                                    <a href="#" className="mr-2"><i className="fa fa-fw fa-comment mr-2"></i>${companyDetails.smsBalance}</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Card className="mb-2">
                            <CardBody>
                                <h6>Company Logo</h6>
                                {companyDetails.picture ?
                                    <img width="100%" height="100%" src={companyDetails.picture}></img>
                                : ""}
                                <CustomInput className="mt-1" onChange={(e) => upload(e)} type="file" id="image" name="image" />
                            </CardBody>
                        </Card>
                        <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Company Name
                                {companyDetails.name ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Email
                                {companyDetails.name ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Phone
                                {companyDetails.phone ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Address
                                {companyDetails.address ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                State
                                {companyDetails.state ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Zip Code
                                {companyDetails.zipCode ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Country
                                {companyDetails.country ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Timezone
                                {companyDetails.timezone ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Measure System
                                {companyDetails.distanceMeasure ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Stripe Connection
                                {companyDetails.stripe ? 
                                    <i className="fa fa-check-circle text-success" /> 
                                    : 
                                    <i className="fa fa-times-circle text-danger" />
                                }
                            </li>
                        </ul>
                    </Col>
                    <Col lg={8}>
                        <UncontrolledTabs initialActiveTabId="config">
                            <Nav pills className="mb-3">
                                <NavItem>
                                    <UncontrolledTabs.NavLink tabId="config">
                                        Config
                                    </UncontrolledTabs.NavLink>
                                </NavItem>
                                <NavItem>
                                    <UncontrolledTabs.NavLink tabId="payments">
                                        Payments
                                    </UncontrolledTabs.NavLink>
                                </NavItem>
                                <NavItem>
                                    <UncontrolledTabs.NavLink tabId="users">
                                        Users
                                    </UncontrolledTabs.NavLink>
                                </NavItem>
                                <NavItem>
                                    <UncontrolledTabs.NavLink tabId="drivers">
                                        Drivers
                                    </UncontrolledTabs.NavLink>
                                </NavItem>
                            </Nav>
                                <UncontrolledTabs.TabContent>
                                    <TabPane tabId="config">
                                        <ConfigTable history={history} id={ props.match.params.id } company={ company }/>
                                    </TabPane>
                                    <TabPane tabId="payments">
                                        <BillingTable reloadParent={getCompany} company={ companyDetails }/>
                                    </TabPane>
                                    <TabPane tabId="users">
                                        <Card>
                                            <CardBody>
                                                <UsersTable reloadParent={getCompany} company={ companyDetails }/>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId="drivers">
                                        <Card>
                                            <CardBody>
                                                <DriversTable reloadParent={getCompany} company={ companyDetails }/>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </UncontrolledTabs.TabContent>
                        </UncontrolledTabs>
                    </Col>
                </Row>
            </Container>
            : ""}
        </React.Fragment>
    );
}

export default withRouter(CompanyDetails);
