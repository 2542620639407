import React, { useState, useEffect } from 'react';
import changelog from "../../../changelog"
import config from "../../../api";
import axios from 'axios'
let axiosConfig = { headers: { Authorization: localStorage.token } };

import {
    Navbar,
    Nav,
    NavItem,
    SidebarTrigger
} from './../../components';
import CheckPlanStatus from '../../components/CheckPlanStatus';

import { NavbarUser } from './NavbarUser';

export const DefaultNavbar = (props) => {

    const [isOnSuper, setIsOnSuper] = useState(false)
    const [backVersion, setBackVersion] = useState(false)

    useEffect(() => props.history.listen(() => {
        if (props.history.location.pathname.includes("/superAdmin") || props.history.location.pathname.includes("/superadmin")) {
            setIsOnSuper(true)
            getBackVersion()
        } else {
            setIsOnSuper(false)
        }
    }), [])

    function getBackVersion() {
        axios.get(`${config.baseUrl}/superadmin/version`, axiosConfig)
            .then(response => {
                if (response.data && response.data.data) {
                    setBackVersion(response.data.data)
                } else {
                    console.log("Could not retrieve version")
                }
            })
            .catch(error => {
                console.log(error)
            });
    }
    return (
        <>
            <Navbar light style={isOnSuper ? { backgroundColor: "#BFEFFF" } : { borderBottom: "1px solid #EBEFF2", backgroundColor: "#ffffff" }} expand="xs" fluid>
                <Nav navbar>
                    <NavItem className="mr-3">
                        <SidebarTrigger />
                    </NavItem>
                    {isOnSuper ?
                        <div className="d-flex align-items-center justify-content-center">
                            version: {changelog[0].version} // api: {backVersion ? backVersion.version : ""}
                        </div>
                        : ""}
                </Nav>
                <Nav navbar className="ml-auto">
                    <NavbarUser className="ml-2" />
                </Nav>
            </Navbar>
            <CheckPlanStatus />
            </>
    )
};
