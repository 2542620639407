import { createSlice } from '@reduxjs/toolkit'

export const plansSlice = createSlice({

    name: 'plans',
    initialState: { value: { selectedPlan: {}, plans: {} } },
    reducers: {
        setPlan: (state, { payload }) => {
            state.value.selectedPlan = payload
        },
        updatePlan: (state, { payload }) => {

            const { planId, plan } = payload

            state.value.plans[planId] = {
                ...state.value.plans[planId],
                ...plan
            }

        },
        insertModuleToPlan: (state, { payload }) => {

            const { planId, module } = payload

            if (!planId || !module) return;

            //If module is already in the plan, do return
            if (state.value.plans[planId]?.modulesIds?.includes(module.moduleId)) return;

            const { moduleId } = module

            if (!planId || !moduleId) return

            state.value.plans[planId] ??= { modulesIds: [], modules: {} }

            state.value.plans[planId].modulesIds ??= []
            state.value.plans[planId].modulesIds.push(moduleId)

            state.value.plans[planId].modules ??= {}
            state.value.plans[planId].modules[moduleId] = { ...module, isNew: true, isDeleted: false }

        },
        updateModuleInPlan: (state, { payload }) => {

            const { planId, module = {} } = payload
            const { moduleId } = module

            state.value.plans[planId].modules ??= {}
            state.value.plans[planId].modules[moduleId] = { ...state.value.plans[planId].modules[moduleId] || {}, ...module }

        },
        removeModuleFromPlan: (state, { payload }) => {

            const { planId, moduleId } = payload

            state.value.plans[planId] ??= { modulesIds: [] }

            const { isNew } = state.value.plans[planId].modules[moduleId] || {}


            if (isNew) {
                delete state.value.plans[planId].modules[moduleId]
                state.value.plans[planId].modulesIds = state.value.plans[planId].modulesIds.filter(id => id !== moduleId)
                return
            }

            state.value.plans[planId].modules[moduleId].isDeleted = true

        },
        recoverModuleFromPlan: (state, { payload }) => {

            const { planId, moduleId } = payload

            state.value.plans[planId] ??= { modules: {} }

            state.value.plans[planId].modules[moduleId].isDeleted = false

        },
        cleanPlansToUpdate: (state) => {
            state.value.plans = {}
        }
    },
})

export const { setPlan,
    updatePlan,
    insertModuleToPlan,
    removeModuleFromPlan,
    recoverModuleFromPlan,
    cleanPlansToUpdate,
    updateModuleInPlan } = plansSlice.actions

export default plansSlice.reducer