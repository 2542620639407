import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import { setPaymentMethod } from '../../reducers/features/cards'
import { useDispatch } from 'react-redux'


const loadingBoxSyle = {
  height: 'calc(1.6em + 0.75rem + 2px)',
  border: '1px solid #DEE2E6',
  borderRadius: '0.25rem',
  backgroundColor: '#F9FAFC'
}

const loadingCircleStyle = {
  backgroundColor: '#DEE2E6'
}

export default function SavedCards({ className, savedCards, noCards }) {

  const [color, setColor] = useState('text-muted')
  const [card, setCard] = useState()
  const dispatch = useDispatch()

  useEffect(() => {

    return () => {
      //Removed the selected cards from the state on unmount 
      dispatch(setPaymentMethod(undefined))
      
    }
  }, [])

  const defaultValue = savedCards?.find((card) => {
    return card.defaultPaymentMethod
  })?.id

  useEffect(() => {

    if (defaultValue) {
      setCard(defaultValue)
      dispatch(setPaymentMethod({ paymentMethodId: defaultValue }))
    }

  }, [defaultValue])
  

  if (savedCards?.length < 1 && !noCards) {
    return (
      <div
        className='my-4 d-flex justify-content-center align-items-center'
        style={loadingBoxSyle}>
        <div className="lds-ellipsis">
          <div style={loadingCircleStyle}></div>
          <div style={loadingCircleStyle}></div>
          <div style={loadingCircleStyle}></div>
          <div style={loadingCircleStyle}></div>
        </div>
      </div>
    )
  }

  const handleChange = (ev) => {

    if (ev.target.value) setColor()
    else setColor('text-muted')

    const selectedCard = ev.target.value
    setCard(selectedCard)
    dispatch(setPaymentMethod({ paymentMethodId: selectedCard }))
  }

  

  return (
    <Input
      type="select"
      name="savedCards"
      id="savedCardsInput"
      className={`${className} ${color}`}
      onChange={handleChange}
      value={card}
    >
      <option key="no_shift" value={false} className='text-muted'>No saved card selected</option>
      {
        savedCards?.map(({ card: { last4 }, id }, index) => {
          return (
            <option key={`card-${index}}`}
              value={id}
            >
              *********{last4}
            </option>
          )
        })
      }
    </Input>
  )
}