import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
} from 'reactstrap';

const bannerStyle = {
  width: '100%',
  height: '20vh',
  backgroundColor: '#FF0017',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: 0,
  flexDirection: 'column',
  padding: '0 2em',
}

export let CheckPlanStatus = ({ }) => {

  const isSubscriptionInactive = useSelector(state => state.errors.value?.includes?.('inactive_subscription'))
  if (!isSubscriptionInactive) return null

  return (
    <div style={bannerStyle} className='text-white '>
      <p className='text-center font-weight-normal'>
        Your credit card was declined for 1 day.
        Your system is now schedule to be put on hold.
        Please send us your update payment information
        to avoid penalties and services interruptions.
      </p>
      <p className='small'>
        Please note, we charge 5% penalty free
        for each week delayed from the first month.
        If a month passes there is a  $150 penalty fee
        to restart your server.
      </p>
      <Link to='/dashboard/profile'>
        <Button
          color="light"
          className='text-dark'
        >
          Pay Now!
        </Button>
      </Link>
    </div>
  )

}