import { createSlice } from '@reduxjs/toolkit'

export const userOnboardsSlice = createSlice({

    name: 'userOnboards',
    initialState: { value: {} },
    reducers: {
        triggerUpdateUserOnboard: (state) => {
            state.value = { updateInfoId: Math.random() }
        }
    },
})

export const {
    triggerUpdateUserOnboard
} = userOnboardsSlice.actions

export default userOnboardsSlice.reducer