// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6-o91L8xIu9MUu6tj1MOb {\n  display: inline-block;\n}", ""]);
// Exports
exports.locals = {
	"starRating": "_6-o91L8xIu9MUu6tj1MOb"
};
module.exports = exports;
