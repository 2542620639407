import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Media,
} from './../../components';

const ToastSuccess = (props) => (
    <React.Fragment>
        <Media className={props.className}>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    {props.title}
                </Media>
                <p>
                    {props.message}<br />
                    <b>{props.info}</b>
                </p>
                {/* <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast }} >
                        I Understand
                    </Button>
                    <Button color="link" onClick={() => { closeToast }}  className="ml-2 text-success">
                        Cancel
                    </Button>
                </div> */}
            </Media>
        </Media>
    </React.Fragment>
);

ToastSuccess.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    info: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
    className: PropTypes.string
};
ToastSuccess.defaultProps = {
    title: "Success!",
    message: "You successfully read this important alert message.",
    className: ""
};

export { ToastSuccess };
