import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    NavItem,
    NavLink
} from './../../components';

const NavbarUser = (props) => {
    const Logout = () => {
        sessionStorage.clear();
        localStorage.clear()
    }

    return(
    <NavItem { ...props }>
        <NavLink to="/" tag={Link} onClick={(e) => (Logout())}>
            <i className="fa fa-power-off"></i>
        </NavLink>
    </NavItem>
    )
};
NavbarUser.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export { NavbarUser };
