
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CardBody } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { setCard } from '../../reducers/features/cards';

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  const handleSubmit = async (event) => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, token } = await stripe.createToken(
      cardElement
    );

    if (error) {
      setError(error.message.toString())
      return
    } else{
      setError()
    }


    dispatch(setCard(token))

  };

  return (
    <CardBody >
      {error ?
        <p>{error}</p>
        : ""}
      <CardElement onChange={handleSubmit} />
    </CardBody>);
};

export default CardForm