import axios from "axios";
import config from "../../../../api";



export default async function createCompany(company, { updateUser = true }) {

    let axiosConfig = { headers: { Authorization: localStorage.token } };

    await axios.post(`${config.baseUrl}/companies`, company, axiosConfig)
    if (updateUser) await getUser()

}

async function getUser() {

    let axiosConfig = { headers: { Authorization: localStorage.token } };
    const res = await axios.get(`${config.baseUrl}/users/me`, axiosConfig)
    localStorage.setItem('user', JSON.stringify(res.data.data))

}