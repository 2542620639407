import React from 'react'

export default function useFromLocalStorage(data) {

    try {
        return JSON.parse(localStorage.getItem(data))
    } catch (err) {
        console.error(err)
        return 
    }

}
