import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import { withRouter } from 'react-router';
import config from "../../../../../api";
import axios from 'axios'
import { CustomSearch } from './CustomSearch';
let axiosConfig = { headers: { Authorization: localStorage.token } };


import {
    Avatar
} from '../../../../components';

const sortCaret = (order) => {
    if (!order)
        return <i className="fa fa-fw fa-sort text-muted"></i>;
    if (order)
        return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
};

export class DriversTable extends React.Component {

    //companies/id/drivers

    constructor(props){
        super(props)
        this.state = {
            drivers: false
        }
    }

    componentDidMount() {
        this.getUsers(this.props.company.companyId)
    }

    getUsers(id){
        axios.get(`${config.baseUrl}/superadmin/companies/` + id + `/drivers`, axiosConfig)
        .then(response => {
            if(response.data && response.data.data){
                this.setState({drivers: response.data.data})
            } else {
                console.log("failed to fetch drivers")
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    createColumnDefinitions() {
        return [
            {
                dataField: 'driverId',
                text: 'ID',
                sort: true
            },
            {
                dataField: 'name',
                text: 'Full Name',
                sort: true,
                formatter: (cell, row) => (
                    <div className="d-flex">
                        <span>{row.name} {row.lastName}</span>
                    </div>
                )
            },
            {
                dataField: 'email',
                text: 'E-mail',
                sort: true
            },
            {
                dataField: 'color',
                text: 'Color',
                sort: true,
                formatter: (cell, row) => (
                    JSON.stringify(cell)
                    
                )
            },
            {
                dataField: 'isActive',
                text: 'Active',
                sort: true
            },
            {
                dataField: 'code',
                text: 'Code',
                sort: true
            }
        ]; 
    }

    render() {
        const columnDefs = this.createColumnDefinitions();
        return (
            <>
            {this.state.drivers && this.state.drivers.length > 0 ?
            <ToolkitProvider
                keyField="driverId"
                data={ this.state.drivers }
                columns={ columnDefs }
                search
                exportCSV
            >
            {
                props => (
                    <React.Fragment>
                        <div className="d-flex justify-content-end align-items-center mb-2">
                            <div className="d-flex ml-auto">
                                <CustomSearch
                                    className="mr-2"
                                    { ...props.searchProps }
                                />
                                {/* <ButtonGroup>
                                    <CustomExportCSV
                                        { ...props.csvProps }
                                    >
                                        Export
                                    </CustomExportCSV>
                                </ButtonGroup> */}
                            </div>
                        </div>
                        <BootstrapTable
                            classes="table-responsive-lg"
                            bordered={ false }
                            responsive
                            hover
                            { ...props.baseProps }
                        />
                    </React.Fragment>
                )
            }
            </ToolkitProvider>
            : "This company has no drivers"}
            </>
        );
    }
}

export default withRouter(DriversTable);