import { LOCALES } from '../constants';

export default {
  [LOCALES.ENGLISH]: {
    'edit-file': 'Edit {file} and save to reload.',
    'hello': 'Hello',
    'demo-placeholder': 'Placeholder sample',
    '_comment': "=======MAIN SIDEBAR=======",
    'dashboard': 'Dashboard',
    'orders': 'Orders',
    'customers': 'Customers',
    'drivers': 'Drivers',
    'vehicles': 'Vehicles',
    'settings': 'Settings',
    'billing': 'Billing',
    'support': 'Support',
    'driver-dashboard': 'Driver-dashboard',
    '_comment2': '==================== LOGIN / REGISTER ====================',
    'Sign In to Dashboard': 'Sign In to Dashboard',
    'Senha': 'Senha',
    'Sign in': 'Sign in',
    'Forgot Password': 'Forgot Password',
    'Text Subtitle': 'Lorem ipsum dolor sit amet',
  },
};
