import React, { useState } from "react";
import OtpInput from "react-otp-input";
import "./../../styles/otp.scss"
import config from "../../../api";
import axios from "axios";
import createCompany from "../../routes/Pages/Register/createCompany";
import { useHistory, Link } from "react-router-dom";
import {
  Button
} from './../../components';
import { withRouter } from 'react-router';

class OtpVerificationClass extends React.Component {




  constructor(props) {
    super(props);

    this.state = {
      otp: '',
      numInputs: 6,
      isDisabled: false,
      hasErrored: false,
      isInputNum: true,
      minLength: 0,
      maxLength: 6,
      placeholder: '',
      errorMessage: '',
      hasSendSuccess: false,
      verified: false,
      userInfo: []

    }

    this.OtpInputContainerStyle = {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "100%",
      justifyContent: "center",
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasSendSuccess || this.state.hasErrored) {

      this.turnOffWarning = setTimeout(() => {
        this.setState(() => ({ hasSendSuccess: false }))
        this.setState(() => ({ hasErrored: false }))
        this.setState({ otp: '' });
      }, 3000);
    }
  }
  componentWillUnmount() {

    clearTimeout(this.turnOffWarning);
  }



  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }

    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    alert(this.state.otp);
  };

  handleRedirect = () => {
    const history = useHistory();
    history.push('/');

  };

  getUserMeInformation = async () => {

    const token = await localStorage.getItem('token');
    const userMe = this;

    if (token) {
      const headers = {
        'Authorization': token
      }

      axios({
        url: `${config.baseUrl}/users/me`,
        method: 'GET',
        headers: headers,
      })

        .then(function (response) {

          const userInfo = response.data.data

          userMe.setState({ userInfo });
          localStorage.setItem('user', JSON.stringify(response.data.data));

          const company = userInfo?.companies?.[0];

          if (company?.companyId) {
            window.location.href = `/`;
          }

          window.location.href = "/company-set-up";

        })
        .catch(function (error) {
          console.log({ error })
          userMe.setState({ errorMessage: error?.response?.data.error });
        });

    }
  };


  getEmailValitationResend = async () => {

    const token = await localStorage.getItem('token');
    const send = this;
    if (token) {
      const headers = {
        'Authorization': token
      }
      try {

        const response = await axios({
          url: `${config.baseUrl}/users/me/verifyEmailResend`,
          method: 'GET',
          headers: headers,
        })

        if (response.data.data.status) {
          send.setState({ hasSendSuccess: true });
        }

      }
      catch (error) {
        send.setState({ errorMessage: error.response.data.error });

      }

    }
  };





  getValidationFromAPI = async () => {


    console.warn(this.state.otp);
    const me = this;
    me.setState({ hasErrored: false });
    const token = await localStorage.getItem('token');
    if (token) {
      const headers = {
        'Authorization': token
      }
      const body = {
        'code': this.state.otp
      }
      try {

        const res = await axios({
          url: `${config.baseUrl}/users/me/verifyemail`,
          method: 'POST',
          headers: headers,
          data: body,
        })

        if (res.data.data.status) {

          localStorage.setItem("verified", true);
          me.setState({ verified: true });
          me.getUserMeInformation();
        }

      } catch (error) {
        console.log(error)
        me.setState({ errorMessage: error.response?.data?.error || error.response?.data?.error?.message });
        me.setState({ hasErrored: true })


      }
    }
  };

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  }




  render() {
    const {
      otp,
      numInputs,
      isDisabled,
      hasErrored,
      hasSendSuccess,
      isInputNum,
      minLength,
      maxLength,
      placeholder,
      errorMessage,
      verified,
    } = this.state;

    return (



      <div className="justify-content-center align-items-center text-center">
        {hasErrored ?
          <div
            className={`alert fade show d-flex alert-danger`}
            role="alert"
          >
            {errorMessage}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"

            >
              &times;
            </span>
          </div>
          : ""}
        {hasSendSuccess ?
          <div
            className={`alert fade show d-flex alert-success`}
            role="alert"
          >
            A new email was sent with a verification code
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"

            >
              &times;
            </span>
          </div>
          : ""}
        {verified ?
          <div
            className={`alert fade show d-flex alert-success`}
            role="alert"
          >
            Code Approved! redirecting...
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"

            >
              &times;
            </span>
          </div>
          : ""}

        <div className="row justify-content-center align-items-center text-center mb-4">
          <OtpInput
            inputStyle="inputStyle mx-3 my-2"
            numInputs={numInputs}
            isDisabled={isDisabled}
            hasErrored={hasErrored}
            errorStyle="error"
            onChange={this.handleOtpChange}
            isInputNum={isInputNum}
            shouldAutoFocus
            value={otp}
            placeholder={placeholder}
            containerStyle={this.OtpInputContainerStyle}
          />
        </div>



        <Button color='success' block onClick={this.getValidationFromAPI}>
          Confirm
        </Button>


        <div className="text-center align-items-center justify-content-center my-5">

          <div className="d-flex mb-5 mt-3">
            <Link to="#" onClick={this.logout} className="text-decoration-none">
              Logout
            </Link>
            <Link to="#" onClick={this.getEmailValitationResend} className="ml-auto text-decoration-none">
              Resend Validation Code
            </Link>
          </div>
        </div>
      </div>


    );
  }

}

export const OtpVerification = withRouter(OtpVerificationClass)