import { createSlice } from '@reduxjs/toolkit'

export const rolesSlice = createSlice({

    name: 'roles',
    initialState: { value: { selectedRole: {}, roles: {} } },
    reducers: {
        setRole: (state, { payload }) => {

            const { roleId } = payload;

            //transform the permissions array to an object
            const permissions = payload.permissions?.reduce((acc, curr) => {
                acc[curr.permissionId] = { ...curr, isOn: true };
                return acc;
            }, {});

            state.value.roles[roleId] = { permissions };

        },
        togglePermissionOnRole: (state, { payload }) => {

            const { roleId, permission } = payload

            if (!roleId || !permission) return;


            const { permissionId } = permission

            if (!roleId || !permissionId) return

            state.value.roles[roleId] ??= { permissions: {} }
            state.value.roles[roleId].permissions ??= {}
            state.value.roles[roleId].permissions[permissionId] ??= { isOn: false, permissionId }
            state.value.roles[roleId].permissions[permissionId].isOn = !state.value.roles[roleId].permissions[permissionId].isOn

        },
        cleanRolesToUpdate: (state) => {
            state.value.roles = {}
        }
    },
})

export const {
    togglePermissionOnRole,
    cleanRolesToUpdate,
    setRole,
} = rolesSlice.actions

export default rolesSlice.reducer