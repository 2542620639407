import { combineReducers } from 'redux'
import sampleReducer from './sampleReducer'
import user from './user'
import plans from './features/plans'
import cards from './features/cards'
import onboards from './features/onboards'
import phases from './features/phases'
import tasks from './features/tasks'
import questions from './features/questions'
import modules from './features/modules'
import products from './features/products'
import users from './features/users'
import roles from './features/roles'
import subscriptions from './features/subscriptions'
import userOnboards from './features/userOnboards'
import errors from './features/errors'
import importSubscriptions from './features/importSubscriptions'

export default combineReducers({
  sampleReducer,
  user,
  plans,
  cards,
  onboards,
  phases,
  tasks,
  questions,
  modules,
  products,
  users,
  roles,
  subscriptions,
  userOnboards,
  errors,
  importSubscriptions,
})