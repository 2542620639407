import React from 'react';

import { SidebarMenu } from '../../components'; 
import {FormattedMessage} from "react-intl";
import { RiDashboardLine } from "react-icons/ri";


import translate from "../../i18nProvider/translate";

// title={translate('edit-file', {file: <code>src/App.js</code>})}

export const SidebarSalesNav = () => (
    <SidebarMenu>
        <SidebarMenu.Item
            icon={<i><RiDashboardLine className="fa-fw" size={24}/></i>}
            title={"Dashboard (Sales)"}
            to='/sales'
            exact 
        ></SidebarMenu.Item>
        
        
    </SidebarMenu >
);
