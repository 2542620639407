const changelog =
[
    {
        "version": "1.2.5",
        "release": "alpha",
        "date": "11th February 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                ""
            ],
            "rebuild": [
                "All the platform maps were rebuilt into a single modular map"
            ],
            "features": [
                "View the driver zones on the dashboard"
            ]
        }
    },
    {
        "version": "1.2.4",
        "release": "alpha",
        "date": "8th February 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                "Admins on the dropdown don't match the admin listing table",
                "When entering settings and billing pages, the information is not up to date",
                "The driver app map is small",
                "Cannot generate 25+ order Routes",
                "Disable Import CSV Static",
                "After registering, Alert with the text 'Registered Successfuly, an e-mail was sent with your verification code.'",
                "Hide driver map or show his zone on the map",
                "Driver without a zone is not assigned an order outside zone on setting number 2",
                "Tested all route settings on a Texas delivery company environment on live servers",
                "Add driver signature, customer signature and proof are not being saved when you change order status",
                "Edit Driver - End Location type 'order' is returned as null",
                "Phone format (12025550135) should be (+1 (202) 555-0135)",
                "Changing the name on the profile gives an error 'E-mail already taken'",
                "If I have a route with 60 orders, it's splitted into 3 sections. If there is an invalid order on section 2, the route will generate anyway with section 1 and 3.",
                "There are 2 start and finish points on a route. Can't see what's the start and end of each route - needs a color",
                "Changed text 'finish' to 'end' on driver application"
            ],
            "rebuild": [
                ""
            ],
            "features": [
                "Choose what unassigned orders to plan",
                "Plan Automatic Route - Display user friendly errors",
                "Show Start and End on the stops list on the driver application"
            ]
        }
    },
    {
        "version": "1.2.3",
        "release": "alpha",
        "date": "1st February 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                "Map access keys, prevent route to generate if the route isn't valid",
                "Default stop duration in minutes",
                "Refresh data when the user imports orders",
                "Custom csv validations",
                "On driverFocusDetails - hide the navbar",
                "Driver Maps - Show the hubs",
                "The CSV template should have only 3 orders",
                "Import CSV Custom - make it possible to ignore fields",
                "Editing a Customer - Enable/Disable Toggles don't save",
                "Plan automatic routes - feedback on why there are no drivers, loading status",
                "Plant automatic routes feedback = There is no active driver for this delivery time window. Please edit your drivers <shifts> to continue",
                "If an order is edited and is inside the route, the route must be recalculated (the order address can change)",
                "Edit driver has no address (front)",
                "Shifts - make the Shift row clickable > straight to edit",
                "End Location - If it's last stop it returns NULL ISLAND",
                "Make stop and route the same field",
                "Tell the user if the csv is the same it autofills",
                "From null To null",
                "Route / Stop - changed the /, it was confusing",
                "Every e-mail and phone number must be clickable",
                "Generate token can only be shown if your plan price is above 40",
                "On customer typeahead ( on orders ) have more information",
                "Whitespace break on order timeline",
                "Improve all tables edits",
                "Api version on the navbar disappears"
            ],
            "rebuild": [
                "Changed the geocoding to Mapbox"
            ],
            "features": [
                "Driver real time location to api",
                "Created a page to display all users",
                "Created a page to display a company drivers",
                "Created a page to display a company users",
                "Disable Admins",
                "Custom phone number input with country flag and validation"
            ]
        }
    },
    {
        "version": "1.2.2",
        "release": "alpha",
        "date": "January 25th 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                "Orders CSV Example - Add/Improve Canada Zipcodes and State",
                "Admin - Shift - text and icons changed",
                "Super admin - Added a loading on the button creating company on sudo",
                "Replaced placeholder text on wizard - driver",
                "Page titles were not being displayed correctly",
                "Last Name on registration",
                "Super Admin - Plan renewal calculation",
                "Config was pulling the wrong api endpoint on beta",
                "Admin - Cannot make a purchase without a card",
                "On Admin - Shift - The ending time of the last period must be higher than everything else and cannot go above midnight",
                "Planning automatic route -> page would not update",
                "Choosing a day with an invalid route would make the map get stuck on that route and would not render another day's route",
                "Show the map when there are no available routes for a given day",
                "Import CSV Static - multiple fixes and error validation",
                "Orders would not be displayed on the driver side maps",
                "On Admin - Admins - Admin confirmation status set to 'Not Matched' when the user is not linked with the company",
                "When there is a major update - Logout everyone",
                "On SuperAdmin - loading button creating a company"
            ],
            "rebuild": [
                "SuperAdmin Navigation"
            ],
            "features": [
                "On Admin - Edit/Create Driver - Center the maps on the driver's start location",
                "On Admin - Edit/Create Driver - Center the driver zone between all hubs",
                "On Admin - Orders - Have a button that downloads an example orders CSV with 30 real-information orders",
                "Super admin - allowed to make a purchase without a card",
                "Global - When there is a major update, everyone gets logged out.",
                "Super admin - blue top navigation",
                "On Driver - Application - retrieve the driver location in real time",
                "On Admin - Import Custom CSV",
                "Super Admin - show the version on the blue bar",
                "Super Admin - change company status",
                "Super Admin - new page listing all users"
            ]
        }
    },
    {
        "version": "1.2.1",
        "release": "alpha",
        "date": "January 15th 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                "On Billing, change 'Payment Method' to 'Default Card' - so the client knows what it is",
                "On Billing, changing the plan would trigger a page reload",
                "On Admin - Edit Route - If I remove an order from a route all the orders become stop #1",
                "On Admin - Edit Route - If I add an unassigned order it is still shown inside the unassigned orders modal",
                "Side navigation menu scroll",
                "Shifts - Periods can overlap",
                "Shifts - Shifts can overlap",
                "Replaced support links"
            ],
            "rebuild": [
                "Admin - Edit Route - Draggable Table Orders and Unassigned Orders logic",
                "Admin - Create/Edit a Shift - Logic",
                "Admin - Create/Edit a Shift - Interface",
                "Admin - Create/Edit a Shift are now the same component/module"
            ],
            "features": [
                "On Admin - on Billing page, when trying to upgrade the maximum orders it sends and e-mail with the request to the support team",
                "On Admin - Edit Route - There is now a button to remove the order from the Route",
                "Rootplanner Academy"
            ]
        }
    },
    {
        "version": "1.2.0",
        "release": "alpha",
        "date": "January 13th 2021",
        "phase": "1",
        "changes": {
            "fixes": [
                "Orders CSV export - All fields are now exported and formatted",
                "Driver Focus renamed to Delivery Routes",
                "Removed location and other internal fields from the field list on super admin - company details",
                "Disabled autocomplete on Typeaheads (google chrome suggestions would stay on top of the search)",
                "Removed placeholder export buttons",
                "Default company country is now US",
                "Solved an issue with package.lock that wouldn't allow you to install new packages",
                "Solved an issue where your onboarding status would become undefined - you would be stuck at the wizard",
                "Solved an issue where when you added an invalid card it would display loading infinitely",
                "While on the wizard, creating a driver allowed the user to go to Add New Hub and Add New Vehicle",
                "Removed forgot password link from the register page",
                "Solved an issue on planpicker driver quantity that caused it to not being sent when subscribing to a plan",
                "Some routes were not loading on the dashboard (see rebuild section)",
                "Some orders and drivers would not be displayd on dashboard (see rebuild section)",
                "You could go to register, login and forgot password pages when logged",
                "Changing the cost per SMS on super wouldn't save",
                "Saving the company details on super would redirect you",
                "Saving user profile would not display errors if it failed",
                "Date on the dashboard would be the date of your first login (see rebuild section)",
                "Changing date would trigger a full page reload when changing the date (see rebuild section)",
                "Dashboard unecessary loading times (see rebuild section)"
            ],
            "rebuild": [
                "Dashboard logic was scattered and sometimes repeated inside components. It has been centralized and improved.",
                "Date was being kept on localStorage, it has been reworked to work trough application state."
            ],
            "features": [
                "On Admin - Billing page now displays purchase details with total monthly and total due now",
                "On Admin - Show all the hubs on the dashboard map.",
                "On Admin - Custom dashboard map icons",
                "On Driver - Center the maps on the driver's starting location. If he doesn't have one, center in Miami.",
                "On Driver - Navigate full route (A button was added next to start route)",
                "On Super - Company is now clickable, you don't have to press the options wheel"
            ]
        }
    }
]

export default changelog

