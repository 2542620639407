import React from 'react';
import faker from '@faker-js/faker/locale/en_US';

import {
    Button,
    Sidebar,
    UncontrolledButtonDropdown,
    DropdownToggle,
    UncontrolledPopover,
    PopoverBody,
    Media,
    Avatar,
    AvatarAddOn
} from './../../../components';
import { randomAvatar } from './../../../utilities';

import { DropdownProfile } from '../Dropdowns/DropdownProfile';
import { FooterAuth } from '../Pages/FooterAuth';
import { FooterText } from '../FooterText';


const SidebarBottomB = () => (
    <React.Fragment>
        
        <Sidebar.Section>
            
            <Sidebar.HideSlim>
                <div
                className="job-title small text-left sidebar__link--muted"
                >
                Version: 0.1.0
                </div>
                <FooterAuth />
            </Sidebar.HideSlim>
            <Sidebar.ShowSlim>
                <div className="text-center">
                    <Button
                        color="link"
                        id="UncontrolledSidebarPopoverFooter"
                        className="sidebar__link p-0"
                    >
                        <i className="fa fa-fw fa-question-circle-o" />
                    </Button>
                    <UncontrolledPopover placement="left-end" target="UncontrolledSidebarPopoverFooter">
                        <PopoverBody>
                            <FooterText />
                            
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            </Sidebar.ShowSlim>
        </Sidebar.Section>
    </React.Fragment>
)

export { SidebarBottomB };
