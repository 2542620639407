import { createSlice } from '@reduxjs/toolkit'

export const productsSlice = createSlice({

    name: 'products',
    initialState: { value: { selectedProduct: {}, products: {} } },
    reducers: {
        setProduct: (state, { payload }) => {
            state.value = { ...state.value, selectedProduct: payload }
        },
        updateProduct: (state, { payload }) => {

            const { productId, product } = payload

            state.value.products[productId] = {
                ...state.value.products[productId],
                ...product
            }

        },
        addPlanToProduct: (state, { payload }) => {

            const { productId, plan } = payload

            if (!productId || !plan) return;

            //If plan is already in the product, do return
            if (state.value.products[productId]?.plansIds?.includes(plan.planId)) return;

            const { planId } = plan

            if (!productId || !planId) return

            state.value.products[productId] ??= { plansIds: [], plans: {} }

            state.value.products[productId].plansIds ??= []
            state.value.products[productId].plansIds.push(planId)

            state.value.products[productId].plans ??= {}
            state.value.products[productId].plans[planId] = { ...plan, isNew: true, isDeleted: false }

        },
        removePlanFromProduct: (state, { payload }) => {

            const { productId, planId } = payload

            state.value.products[productId] ??= { plansIds: [] }

            const { isNew } = state.value.products[productId].plans[planId] || {}


            if (isNew) {
                delete state.value.products[productId].plans[planId]
                state.value.products[productId].plansIds = state.value.products[productId].plansIds.filter(id => id !== planId)
                return
            }

            state.value.products[productId].plans[planId].isDeleted = true

        },
        recoverPlanFromProduct: (state, { payload }) => {

            const { productId, planId } = payload

            state.value.products[productId] ??= { plans: {} }

            state.value.products[productId].plans[planId].isDeleted = false

        },
        cleanProductsToUpdate: (state) => {
            state.value.products = {}
        },
        setBestPlan: (state, { payload }) => {

            const { productId, planId } = payload
            state.value.products[productId].bestPlan = planId

        },
        sortPlansFromProduct: (state, { payload }) => {

            const { productId, plansIds } = payload
            const plansOrderEdited = plansIds.some((planId, index) => String(state.value.products?.[productId]?.plansIds?.[index]) !== String(planId))

            state.value.products[productId].plansOrderEdited = plansOrderEdited
            state.value.products[productId].plansIds = plansIds


        },
    },
})

export const { setProduct,
    updateProduct,
    addPlanToProduct,
    removePlanFromProduct,
    recoverPlanFromProduct,
    cleanProductsToUpdate,
    setBestPlan,
    sortPlansFromProduct
} = productsSlice.actions

export default productsSlice.reducer