import React from 'react';
import faker from '@faker-js/faker/locale/en_US';
import { Link } from 'react-router-dom';
import useUserPicture from './hooks/useUserPicture.js';

import {
    Sidebar,
    UncontrolledButtonDropdown,
    Avatar,
    AvatarAddOn,
    DropdownToggle,
    Media
} from './../../../components';
import { DropdownProfile } from '../Dropdowns/DropdownProfile';


const SidebarTopA = () => {

    const [avatarImg, setUserPicture] = useUserPicture()

    return (
        <React.Fragment>
            { /* START: Sidebar Default */}
            <Sidebar.HideSlim>
                <Sidebar.Section className="pt-0 pl-0 ">
                    <Link to="/" className="d-block justify-content-left">

                    </Link>

                    <UncontrolledButtonDropdown direction="up" className="mb-3 d-flex justify-content-left">

                        <DropdownToggle color="link" className="btn-profile pl-0 pb-0">
                            <div className="d-flex justify-content-left">
                                {avatarImg ?
                                    <Avatar.Image
                                        size="lg"
                                        src={avatarImg}
                                        addOns={[
                                            <AvatarAddOn.Icon
                                                className="fa fa-circle"
                                                color="white"
                                                key="avatar-icon-bg"
                                            />,
                                            <AvatarAddOn.Icon
                                                className="fa fa-circle"
                                                color="success"
                                                key="avatar-icon-fg"
                                            />
                                        ]}
                                    /> : <div
                                        className='rounded-circle d-flex justify-content-center align-items-center border'
                                        style={{
                                            backgroundColor: '#CED4DA',
                                            width: '60px',
                                            height: '60px',
                                        }}
                                    >
                                        <i
                                            className='fa fa-fs fa-user text-white'
                                            style={{ fontSize: '3vh' }}
                                        />
                                    </div>}

                            </div>
                            {localStorage.user ?
                                <Media body>
                                    <div className="d-flex justify-content-left">
                                        {localStorage.user && JSON.parse(localStorage.user).user ? JSON.parse(localStorage.user).user.name : ""} <i className="fa fa-fw fa-angle-up ml-1"></i>
                                    </div>
                                    <div className="d-flex justify-content-left text-muted">
                                        <p className="small">{localStorage.user && JSON.parse(localStorage.user).user ? JSON.parse(localStorage.user).user.email : ""}</p>
                                    </div>
                                </Media>
                                : ""}
                        </DropdownToggle>
                        <DropdownProfile setUserPicture={setUserPicture} />
                    </UncontrolledButtonDropdown>
                    {/* <div className="small sidebar__link--muted">
                    { faker.name.jobTitle() }
                </div> */}
                </Sidebar.Section>
            </Sidebar.HideSlim>
            { /* END: Sidebar Default */}

            { /* START: Sidebar Slim */}
            <Sidebar.ShowSlim >
                <Sidebar.Section>
                    <div className='d-flex justify-content-center'>
                        <Avatar.Image
                            size="sm"
                            src={avatarImg}
                            addOns={[
                                <AvatarAddOn.Icon
                                    className="fa fa-circle"
                                    color="white"
                                    key="avatar-icon-bg"
                                />,
                                <AvatarAddOn.Icon
                                    className="fa fa-circle"
                                    color="success"
                                    key="avatar-icon-fg"
                                />
                            ]}
                        />
                    </div>
                </Sidebar.Section>
            </Sidebar.ShowSlim>
            { /* END: Sidebar Slim */}
        </React.Fragment>
    )
}

export { SidebarTopA };
