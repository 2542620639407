// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3DoqDAkx41sgc6na1wN7jX {\n  cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"checkable__trigger": "_3DoqDAkx41sgc6na1wN7jX"
};
module.exports = exports;
