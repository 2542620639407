import React from 'react';
import { Link } from 'react-router-dom';

import {
    Form,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroup,
    Button,
    Label,
    EmptyLayout,
    ThemeConsumer
} from './../../../components';

import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";

const Error404 = () => (
    <EmptyLayout>
        <EmptyLayout.Section center>
            { /* START Header */}
            <HeaderAuth 
                title="404: Oops! Page not found"
                text="You are trying to go to a page that doesn't exist. You may have typed the wrong URL or clicked on a link to a feature that hasn't been completed yet. If you are having an issue and believe this page should work please feel free to contact our support team. We recommend you send a video of the error and how you are getting to this page. Thank you!"
            />
            { /* END Header */}
            { /* START Bottom Links */}
            <div className="d-flex mb-5">
                <Link to="/">
                    Back to Home
                </Link>
                <a href="https://sprwt.freshdesk.com/" target="_blank" className="ml-auto text-decoration-none">
                    Support
                </a>
            </div>
            { /* END Bottom Links */}
            { /* START Footer */}
            <FooterAuth />
            { /* END Footer */}
        </EmptyLayout.Section>
    </EmptyLayout>
);

export default Error404;
