import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../api';

import {
    Form,
    FormGroup,
    FormText,
    Input,
    Button,
    Label,
    EmptyLayout,
    ThemeConsumer
} from './../../../components';

let axiosConfig = {headers: {Authorization: localStorage.token}};
import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";

const ForgotPassword = () => {
    const history = useHistory();
    const [email, setEmail] = useState(null)
    const [error, setError] = useState(false)
    function sendCode(e) {
        e.preventDefault();
        if(email==null){
            setError("email is required")
        } else {
            let payload = { email: email }
            axios.post(`${config.baseUrl}/users/recoverypassword`, payload, axiosConfig)
            .then(function (response) {
                if(response.data.error){
                    setError(response.data.error)
                } else {
                    history.push("/forgot-password/confirm")
                }
            })
            .catch(function (error) {
                /* setError(error.response.data.error.toString()) */
                console.log(error)
            });
        }
    }
    return(
    <EmptyLayout>
        <EmptyLayout.Section center>
            { /* START Header */}
            <HeaderAuth 
                title="Forgot Password"
            />
            { /* END Header */}
            { /* START Form */}
            <Form className="mb-3">
                <FormGroup>
                    <Label for="emailAdress">
                        Email Address
                    </Label>
                    {error ? <p className="text-danger">{error}</p> : ""}
                    <Input required value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="emailAddress" placeholder="Enter..." className="bg-white" />
                    <FormText color="muted">
                        We'll never share your email with anyone else.
                    </FormText>
                </FormGroup>
                <div className="d-flex">
                    <ThemeConsumer>
                    {
                        ({ color }) => (
                            <Button color={ color } tag={ Link } onClick={(e) => sendCode(e)} className="align-self-center">
                                Reset Password
                            </Button>
                        )
                    }
                    </ThemeConsumer>
                    <Button color="link" tag={ Link } to="/" className="align-self-center ml-auto pr-0 text-decoration-none">
                        <i className="fa fa-angle-left mr-2"></i> Back to Home
                    </Button>
                </div>
            </Form>
            { /* END Form */}
            { /* START Bottom Links */}
            <div className="d-flex mb-5">
                <Link to="/login" className="text-decoration-none">
                    Login
                </Link>
                <Link to="/register" className="ml-auto text-decoration-none">
                    Register
                </Link>
            </div>
            { /* END Bottom Links */}
            { /* START Footer */}
            <FooterAuth />
            { /* END Footer */}
        </EmptyLayout.Section>
    </EmptyLayout>
)}

export default ForgotPassword;
