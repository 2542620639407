import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SetPictureModal } from '../../SetPictureModal/SetPictureModal.js';

import {
    DropdownMenu,
    DropdownItem
} from './../../../components';

const DropdownProfile = (props) => {

    const [showPictureModal, setShowPictureModal] = useState(false)

    function logout() {
        sessionStorage.clear();
        localStorage.clear();
    }

    return (
        <React.Fragment>
            <SetPictureModal
                setUserPicture={props.setUserPicture}
                opened={showPictureModal}
                close={() => setShowPictureModal(false)} />
            <DropdownMenu right={props.right} >
                <DropdownItem
                    onClick={() => setShowPictureModal(true)}
                >
                    Change Picture
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag={Link} to="" onClick={(e) => logout()}>
                    <i className="fa fa-fw fa-sign-out mr-2"></i>
                    Sign Out
                </DropdownItem>
            </DropdownMenu>
        </React.Fragment>
    )
}
DropdownProfile.propTypes = {
    position: PropTypes.string,
    right: PropTypes.bool
};
DropdownProfile.defaultProps = {
    position: ""
};

export { DropdownProfile };
