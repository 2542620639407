import React from 'react';

import { SidebarMenu } from '../../components';
import { RiUserStarLine } from "react-icons/ri";
import { FaTasks } from 'react-icons/fa'
import { RiDashboardLine } from "react-icons/ri";

export const SidebarSuperNav = ({
    isOnOnboarderMode,
    isOnSalesMode,
}) => {

    const permissions = JSON.parse(localStorage.user || '{}')?.user?.permissions
    const role = JSON.parse(localStorage.user || '{}')?.user?.role
    const isSuperadmin = role === 'superadmin'

    let sideBarItems = []


    if (role?.toLowerCase() === 'superadmin' && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='supeadmin'
                icon={<i className="fa fa-fw fa-star"></i>}
                title="Dashboard"
                to='/superadmin'
                exact
            ></SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view roles') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='roles'
                icon={<i><RiUserStarLine className="fa-fw" size={26} /></i>}
                title="Roles"
                to='/superadmin/roles'
                exact
            ></SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view products') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='products'
                icon={<i className="fa fa-fw fa-cubes "></i>}
                title="Products"
                to='/superadmin/products'
                exact
            >
                <SidebarMenu.Item
                    title="Plans"
                    to='/superadmin/plans'
                    exact
                ></SidebarMenu.Item>
                <SidebarMenu.Item
                    title="Modules"
                    to='/superadmin/modules'
                    exact
                ></SidebarMenu.Item>
            </SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view clients') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(

            <SidebarMenu.Item
                key='users'
                icon={<i className="fa fa-fw fa-users" />}
                title="Users"
                to='/superadmin/users'
                exact
            >
            </SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view companies') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='companies'
                icon={<i className="fa fa-fw fa-building"></i>}
                title="Companies"
                to='/superadmin/companies'
                exact
            >
            </SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view onboards') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='onboards'
                icon={<i><FaTasks className="fa-fw" size={22} /></i>}
                title="Onboards"
                to='/superadmin/onboards'
                exact
            >
            </SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view disputes') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='disputes'
                icon={<i className="fa fa-fw fa-dollar"></i>}
                title={'Disputes'}
                to='/superadmin/disputes'
                exact
            ></SidebarMenu.Item>
        )
    }

    if (permissions?.includes('view scheduled events') && !isOnSalesMode && !isOnOnboarderMode) {
        sideBarItems.push(
            <SidebarMenu.Item
                key='cron'
                icon={<i className="fa fa-fw fa-clock-o" />}
                title="Jobs"
                to='/superadmin/cron'
                exact
            ></SidebarMenu.Item>
        )
    }

    if (isOnOnboarderMode) {

        sideBarItems.push(
            <SidebarMenu.Item
                key='clients'
                icon={<i><RiDashboardLine className="fa-fw" size={24} /></i>}
                title={"Dashboard"}
                to='/onboarder'
                exact
            ></SidebarMenu.Item>
        )

        sideBarItems.push(
            <SidebarMenu.Item
                key='onboarderunassignedclients'
                icon={<i className="fa fa-fw fa-users" />}
                title={"Customers"}
                to='/onboarder/clients'
                exact
            ></SidebarMenu.Item>
        )

    }

    if (isOnSalesMode) {

        sideBarItems.push(
            <SidebarMenu.Item
                key='clients'
                icon={<i><RiDashboardLine className="fa-fw" size={24} /></i>}
                title={"Dashboard"}
                to='/sales'
                exact
            ></SidebarMenu.Item>
        )

        sideBarItems.push(
            <SidebarMenu.Item
                key='salesclients'
                icon={<i className="fa fa-fw fa-users" />}
                title={"Customers"}
                to='/sales/clients'
                exact
            ></SidebarMenu.Item>
        )

        sideBarItems.push(
            <SidebarMenu.Item
                key='usalesnassignedclients'
                icon={<i className="fa fa-fw fa-user-plus" />}
                title={"Unassigned"}
                to='/sales/unassignedcustomers'
                exact
            ></SidebarMenu.Item>
        )


    }


    return (<SidebarMenu>
        {
            sideBarItems
        }
    </SidebarMenu>
    );
}