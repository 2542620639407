import React from 'react';
import PropTypes from 'prop-types';
import {  
    PieChart, 
    Pie,
    Cell
} from './../../../../components/recharts';

import colors from './../../../../colors';

const TinyDonutChart = (props) => {
    const data = [
        {name: 'Group A', value: 40+Math.random()*100},
        {name: 'Group B', value: Math.random()*100}
    ];
    return (
        <PieChart width={ 40 } height={ 40 }>
            <text
                x={20}
                y={21}
                fill={ colors[ props.textColor ]}
                textAnchor="middle"
                dominantBaseline="middle"
                style={{fontSize: "10px"}}
                >
                {props.percentage}%
            </text>
            <Pie
                data={props.data}
                dataKey="value"
                stroke={ colors[ props.strokeColor ] }
                innerRadius={ 14 }
                outerRadius={ 20 } 
                fill={ colors[ props.pieBg ] }
            >
            <Cell fill={ colors[ props.pieColor ] } />
            </Pie>
        </PieChart>
    )
};

TinyDonutChart.propTypes = {
    pieColor: PropTypes.string,
    strokeColor: PropTypes.string,
    pieBg: PropTypes.string,
    textColor : PropTypes.string,
    percentage : PropTypes.string,
    data : PropTypes.object
};
TinyDonutChart.defaultProps = {
    pieColor: "primary",
    strokeColor: "white",
    pieBg: "200",
    textColor: "primary",
    percentage: "0",
    data: [{name: 'Group A', value: 0},{name: 'Group B', value: 100}
    ]
};

export { TinyDonutChart };
