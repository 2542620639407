import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';

const HeaderSub = (props) => (
    <Media className={ `mb-3 ${ props.className }` }>
        <Media body>
            <h4 className="mt-1 mb-1">
                {props.title}
            </h4>
            <p className="mb-0">{props.children || props.subTitle}</p>
        </Media>
    </Media>
)
HeaderSub.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string
};
HeaderSub.defaultProps = {
    title: "Waiting for Data...",
    subTitle: "Waiting for Data..."
};

export { HeaderSub };
