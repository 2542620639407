import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import config from "../../../../api";
import translate from "../../../i18nProvider/translate";
import axios from 'axios';

import {
    FormGroup,
    Button,
    EmptyLayout,
} from './../../../components';

import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";
import changelog from "../../../../changelog";




const Login = () => {
    const { register, handleSubmit, errors } = useForm();
    const [message, setMessage] = useState();

    const history = useHistory();

    const [state, setState] = useState({
        verify: false,
        needChangePassword: false,
    });

    useEffect(() => {
        setState({
            verify: false,
            needChangePassword: false,
        });
    }, [])


    const onSubmit = async (data, e) => {
        setMessage({
            data: "Login is in progress...",
            type: "alert-warning",
        });
        console.warn(data);

        fetch(`${config.baseUrl}/users/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {

                const hasError = "error" in data && data.error != null;

                setMessage({
                    data: hasError ? data.error : "Logged in successfully, redirecting...",
                    type: hasError ? "alert-danger" : "alert-success",
                });

                if (!hasError) {
                    localStorage.setItem('v', changelog[0].version)

                    setState({
                        verified: data.verified,
                        needChangePassword: data.needChangePassword,
                    });

                    localStorage.setItem("token", data.access_token);
                    localStorage.setItem("verified", data.verified);

                    if (data.access_token) {
                        let axiosConfig = {
                            headers: {
                                Authorization: data.access_token
                            }
                        };

                        axios.get(`${config.baseUrl}/users/me`, axiosConfig)
                            .then(function (response) {
                                if (response.data.data) {
                                    localStorage.setItem('user', JSON.stringify(response.data.data))
                                    if (response.data.data.user.isSuperAdmin) {
                                        localStorage.setItem("sudo", true)
                                    }
                                    if (data.verified == false) {
                                        setMessage({
                                            data: hasError ? data.error : "Validation required, redirecting...",
                                            type: hasError ? "alert-warning" : "alert-success",
                                        });
                                        history.push("/confirmation");
                                    }
                                    if (data.needChangePassword == true) {
                                        setMessage({
                                            data: hasError ? data.error : "Password change needed, redirecting...",
                                            type: hasError ? "alert-warning" : "alert-success",
                                        });
                                        history.push("/profile");
                                    }
                                    if (data.needChangePassword == false && data.verified == true) {
                                        setMessage({
                                            data: hasError ? data.error : "Logged in successfully, redirecting...",
                                            type: hasError ? "alert-danger" : "alert-success",
                                        });
                                        window.location = "/"
                                    }
                                } else {
                                    localStorage.clear()
                                    window.location = "/login"
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    }
                }
                !hasError && e.target.reset();

            });

    };

    return (
        <>

            <EmptyLayout>
                <EmptyLayout.Section center>
                    { /* START Header */}
                    <HeaderAuth
                        title={translate('Sign In to Dashboard')}
                    />
                    <div
                        className={``}
                    >

                        {message && (
                            <div
                                className={`alert fade show d-flex ${message.type}`}
                                role="alert"
                            >
                                {message.data}
                                <span
                                    aria-hidden="true"
                                    className="ml-auto cursor-pointer"
                                    onClick={() => setMessage(null)}
                                >
                                    &times;
                                </span>
                            </div>
                        )}
                        <fieldset className="">

                            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <FormGroup>
                                    <div className="form-group">
                                        <label htmlFor="inputForEmail">Email address</label>
                                        <span className="mandatory">*</span>
                                        <input
                                            id="inputForEmail"
                                            name="email"
                                            type="email"
                                            className="form-control"
                                            aria-describedby="Enter email address"
                                            placeholder="Enter email address"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: "Please enter your email address",
                                                },
                                            })}
                                        />

                                        {errors.email && (
                                            <span className="text-danger">
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <div className="form-group">
                                        <label htmlFor="inputForPassword">Password</label>
                                        <span className="mandatory">*</span>
                                        <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            id="inputForPassword"
                                            placeholder="Enter password"
                                            ref={register({
                                                required: {
                                                    value: true,
                                                    message: "Please enter password",
                                                },
                                            })}
                                        />
                                        {errors.password && (
                                            <span className="text-danger">
                                                {errors.password.message}
                                            </span>
                                        )}
                                    </div>
                                </FormGroup>

                                <Button color='success' block type="submit">
                                    Sign In
                                </Button>

                                <div className="d-flex mb-5 mt-3">
                                    <Link to="/forgot-password" className="text-decoration-none">
                                        Forgot Password
                                    </Link>

                                    <Link to="/register" className="ml-auto text-decoration-none">
                                        Don't have an account?
                                    </Link>

                                </div>

                            </form>
                            <div className='text-center'>
                                <FooterAuth />
                            </div>

                        </fieldset>

                    </div>
                </EmptyLayout.Section>
            </EmptyLayout>


        </>
    )
}

export default Login;
