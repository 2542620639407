import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import _ from 'lodash';
import { withRouter } from 'react-router';
import config from "../../../../../api";
import axios from 'axios'
import { CustomSearch } from './CustomSearch';
let axiosConfig = { headers: { Authorization: localStorage.token } };

const sortCaret = (order) => {
    if (!order)
        return <i className="fa fa-fw fa-sort text-muted"></i>;
    if (order)
        return <i className={`fa fa-fw text-muted fa-sort-${order}`}></i>
};

export class UsersTable extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            users: false
        }
    }

    componentDidMount() {
        this.getUsers(this.props.company.companyId)
    }

    getUsers(id){
        axios.get(`${config.baseUrl}/superadmin/companies/` + id + `/users`, axiosConfig)
        .then(response => {
            if(response.data && response.data.data){
                this.setState({users: response.data.data})
            } else {
                console.log("failed to fetch users")
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    createColumnDefinitions() {
        return [
            {
                dataField: 'userCompanyId',
                text: 'ID',
                sort: true
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true
            },
            {
                dataField: 'email',
                text: 'E-mail',
                sort: true
            },
            {
                dataField: 'isActive',
                text: 'Active',
                sort: true
            }
        ]; 
    }

    render() {
        const columnDefs = this.createColumnDefinitions();
        return (
            <>
            {this.state.users && this.state.users.length > 0 ?
                <ToolkitProvider
                    keyField="userId"
                    data={ this.state.users }
                    columns={ columnDefs }
                    search
                    exportCSV
                >
                {
                    props => (
                        <React.Fragment>
                            <div className="d-flex justify-content-end align-items-center mb-2">
                                <div className="d-flex ml-auto">
                                    <CustomSearch
                                        className="mr-2"
                                        { ...props.searchProps }
                                    />
                                    {/* <ButtonGroup>
                                        <CustomExportCSV
                                            { ...props.csvProps }
                                        >
                                            Export
                                        </CustomExportCSV>
                                    </ButtonGroup> */}
                                </div>
                            </div>
                            <BootstrapTable
                                classes="table-responsive-lg"
                                bordered={ false }
                                responsive
                                hover
                                { ...props.baseProps }
                            />
                        </React.Fragment>
                    )
                }
                </ToolkitProvider>
            : "This company has no users"}
            </>
        );
    }
}

export default withRouter(UsersTable);