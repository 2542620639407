import React from 'react';
import {
    Sidebar,
    SidebarTrigger,
} from './../../components';

import { SidebarAdminNav } from './SidebarAdminNav';
import { SidebarSuperNav } from './SidebarSuperNav';
import { SidebarTopC } from '../../routes/components/Sidebar/SidebarTopC';
import { SidebarTopA } from '../../routes/components/Sidebar/SidebarTopA'
import { SidebarTopB } from '../../routes/components/Sidebar/SidebarTopB'
import { SidebarBottomB } from '../../routes/components/Sidebar/SidebarBottomB'
import { ToastContainer } from 'react-toastify';

export const DefaultSidebar = (props) => {

    let isOnSuper = props.history.location.pathname.includes("/superAdmin") || props.history.location.pathname.includes("/superadmin") || sessionStorage.getItem('adminMode') === 'superadmin'
    const isOnAdminMode = sessionStorage.getItem('adminMode') === 'admin' || sessionStorage.getItem('adminMode') === 'superadmin' || sessionStorage.getItem('adminMode') === 'sales' || sessionStorage.getItem('adminMode') === 'onboarder' || props.history.location.pathname.includes("/sales") || props.history.location.pathname.includes("/onboarder")
    const isOnSalesMode = sessionStorage.getItem('adminMode') === 'sales' || props.history.location.pathname.includes("/sales")
    const isOnOnboarderMode = sessionStorage.getItem('adminMode') === 'onboarder' || props.history.location.pathname.includes("/onboarder")

    return (
        <Sidebar>
            { /* START SIDEBAR-OVERLAY: Close (x) */}
            <Sidebar.Close>
                <SidebarTrigger tag={'a'} href="#">
                    <i className="fa fa-times-circle fa-fw"></i>
                </SidebarTrigger>
            </Sidebar.Close>
            { /* END SIDEBAR-OVERLAY: Close (x) */}

            { /* START SIDEBAR: Fixed Section */}
            <Sidebar.Section className="sidebar__brand__text h4 fw-300 m-0">
                <SidebarTopB />
            </Sidebar.Section>
            <Sidebar.Section>
                <SidebarTopA />
            </Sidebar.Section>

            { /* END SIDEBAR: Fixed Section */}

            { /* START SIDEBAR: Mobile Scroll Wrapper */}
            <Sidebar.MobileFluid>
                { /* START SIDEBAR: Everywhere */}
                <Sidebar.Section fluid cover>
                    <SidebarTopC
                        isOnSuper={isOnSuper}
                        isOnOnboarderMode={isOnOnboarderMode}
                        isOnSalesMode={isOnSalesMode}
                    />

                    {
                        !isOnAdminMode && (
                            <SidebarAdminNav
                                isOnOnboarderMode={isOnOnboarderMode}
                                isOnSalesMode={isOnSalesMode}
                            />
                        )
                    }

                    {
                        isOnAdminMode && (
                            <SidebarSuperNav
                                isOnOnboarderMode={isOnOnboarderMode}
                                isOnSalesMode={isOnSalesMode}
                            />
                        )
                    }

                </Sidebar.Section>
                { /* END SIDEBAR: Everywhere */}
                <SidebarBottomB />
                {/* <LanguageSelect /> */}

            </Sidebar.MobileFluid>
            { /* END SIDEBAR: Mobile Scroll Wrapper */}

            {/* Enabling toast across all website */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                draggable={false}
                hideProgressBar={false}
            />
        </Sidebar>
    )
};
