import React from 'react';
import { Link } from 'react-router-dom';
import { OtpVerification } from "../../../components/OtpVerification/OtpVerification";
import {
    Form,
    FormGroup,
    FormText,
    Input,
    CustomInput,
    Button,
    Label,
    EmptyLayout,
    ThemeConsumer
} from './../../../components';
import "./Confirmation.scss";
import axios from 'axios';
import config from "../../../../api";
import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";



const Confirmation = () => (


    <EmptyLayout>

    
    
        <EmptyLayout.Section center width='550px'>
            { /* START Header */}
            <HeaderAuth 
                title="Confirm Your Email Address"
                icon="envelope"
                iconClassName='success'
                text={(
                    <React.Fragment className="redd">
                        A confirmation email was sent to 
                        your inbox with a code for validation.
                        Please add that code in the fiels below.
                    </React.Fragment>
                )}
            />
            { /* END Header */}
            <div className="text-center align-items-center justify-content-center my-4">
              <OtpVerification />  
            </div>

            
            
            

            { /* START Bottom Links */}
            {/* <div className="text-center mb-5">
                <Link to="/" className="text-decoration-none">
                    <i className="fa fa-angle-left mr-2"></i>Back to Home
                </Link>
            </div> */}
            { /* END Bottom Links */}
            { /* START Footer */}
            <FooterAuth />
            { /* END Footer */}
        </EmptyLayout.Section>
        </EmptyLayout>
);

export default Confirmation;
