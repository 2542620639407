import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";

import {
  Button,
  EmptyLayout,
  Label
} from './../../../components';

import PhoneInput from 'react-phone-input-2'

import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";
import createUser from "./createUser";
import ReCAPTCHA from "react-google-recaptcha"
import { useRef } from 'react';
import config from "../../../../config";

const Register = () => {
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const history = useHistory();
  const captchaRef = useRef(null)

  const onSubmit = async (data, e) => {
    
    data.phone = phoneNumber;
    setMessage({
      data: "Registration is in progress...",
      type: "alert-warning",
    });

    data.token = captchaRef.current.getValue();
    captchaRef.current.reset();

    try {

      await createUser(data)
      setMessage({
        data: "Registered successfully",
        type: "alert-success",
      });
      console.log("User creation succes")
      setTimeout(() => window.location = '/', 1000)

    } catch (err) {
      console.log("User creation error", err)
      setMessage({
        data: err.response?.data?.error,
        type: "alert-danger",
      });
    }
  };

  return (
    <EmptyLayout>
      <EmptyLayout.Section center width={480}>
        { /* START Header */}
        <HeaderAuth
          title="Create an Account"
        />

        <div>
          {message && (
            <div
              className={`alert fade show d - flex ${message.type} `}
              role="alert"
            >
              {message.data}
              <span
                aria-hidden="true"
                className="ml-auto cursor-pointer float-right"
                onClick={() => setMessage(null)}
              >
                &times;
              </span>
            </div>
          )}
          <fieldset className="">


            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
              <div className="form-group">
                <label htmlFor="inputForName">First Name</label>
                <span className="mandatory">*</span>
                <input
                  id="inputForName"
                  name="name"
                  type="text"
                  className="form-control"
                  aria-describedby="Enter your name"
                  placeholder="Enter your first name"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your name",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.name && (
                  <span className="text-danger">
                    {errors.name.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="inputForLastName">Last Name</label>
                <span className="mandatory">*</span>
                <input
                  id="inputForLastName"
                  name="lastName"
                  type="text"
                  className="form-control"
                  aria-describedby="Enter your last name"
                  placeholder="Enter your last name"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your last name",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.name && (
                  <span className="text-danger">
                    {errors.lastName?.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="inputForEmail">Email Address</label>
                <span className="mandatory">*</span>
                <input
                  id="inputForEmail"
                  name="email"
                  type="email"
                  className="form-control"
                  aria-describedby="Enter email address"
                  placeholder="Enter email address"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter your email address",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid email address",
                    },
                    minLength: {
                      value: 6,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
                {errors.email && (
                  <span className="text-danger">
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="inputForPhoneNumber">Phone Number</label>
                <PhoneInput 
                  country={'us'}
                  value={phoneNumber}
                  onChange={(value, country, e, formattedValue) => setPhoneNumber(formattedValue)}
                />
                {errors.phoneNumber && (
                  <span className="text-danger">
                    {errors.phoneNumber.message}
                  </span>
                )}
              </div>
              <div className="form-group">
                {
                  errors.name && (
                    <span className="text-danger">
                      {errors.lastName?.message}
                    </span>
                  )
                }
              </div>
              <div className="form-group">
                <label htmlFor="inputForPassword">Password</label>
                <span className="mandatory">*</span>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  id="inputForPassword"
                  placeholder="Enter password"
                  ref={register({
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                    minLength: {
                      value: 6,
                      message: "Minimum 6 characters are allowed",
                    },
                    maxLength: {
                      value: 255,
                      message: "Maximum 255 characters are allowed",
                    },
                  })}
                />
                {errors.password && (
                  <span className="text-danger">
                    {errors.password.message}
                  </span>
                )}
              </div>

              <Button color='success' block type="submit">
                Create Account
              </Button>

              <div className="d-flex mb-1 mt-3">
                {/* <Link to="/forgot-password" className="text-decoration-none">
                    Forgot Password
                </Link> */}
                <Link to="/login" className="ml-auto text-decoration-none">
                  Login
                </Link>
              </div>
              <div className="mb-4 d-flex justify-content-center">
                <ReCAPTCHA 
                  sitekey={config.siteKey}
                  ref={captchaRef}
                />
              </div>
            </form>
          </fieldset>
        </div>
        {<FooterAuth />}
        { /* END Footer */}
      </EmptyLayout.Section>
    </EmptyLayout>
  )
}

export default Register;
