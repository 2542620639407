import { createSlice } from '@reduxjs/toolkit'


export const phasesSlice = createSlice({
    name: 'phases',
    initialState: {
        value: {
            //Phases being updated
            phases: {},
            //Controls the modal for adding a phase to a onboard
            openAddPhasesModal: false,
            //Phases selected in the phases selectable table
            phasesSelected: []
        }
    },
    reducers: {
        cleanPhasesToUpdate: (state) => {
            state.value.phases = {}
        },
        updatePhase: (state, { payload }) => {

            const { phaseId, phasePayload } = payload

            if (!phaseId || !phasePayload) return

            delete phasePayload.tasks

            if (state.value.phases[phaseId]?.tasksIds) {
                delete phasePayload.tasksIds
            }

            state.value.phases[phaseId] = {
                ...state.value.phases[phaseId],
                ...phasePayload,
                dontUpdate: false,
            }

        },
        skiptUpdate: (state, { payload: { phaseId } = {} }) => {

            state.value.phases[phaseId] ??= {}
            state.value.phases[phaseId].dontUpdate = true

        },
        openAddPhasesModal: (state, { payload }) => {
            state.value.openAddPhasesModal = true
            state.value.phaseModalOnboard = payload.onboardId
        },
        closeAddPhasesModal: (state) => {
            state.value.openAddPhasesModal = false
        },
        setPhasesSelectedOnTable: (state, { payload }) => {
            state.value.openAddPhasesModal = { payload }
        },
        addTaskToPhase: (state, { payload: task }) => {

            const { taskId, isNew, phaseId, isNewSaved } = task

            //Either the task is new (the user has just created it) or saved (if he wants to add an exiting task the the phase)
            if (!phaseId || (!isNew && !isNewSaved)) return

            state.value.phases[phaseId] ??= { tasks: {} }
            state.value.phases[phaseId].tasks ??= {}

            state.value.phases[phaseId].tasks[taskId] = task

            state.value.phases[phaseId].tasksIds ??= []

            state.value.phases[phaseId].tasksIds.push(taskId)

        },
        removeTaskFromPhase: (state, { payload }) => {

            const { taskId, phaseId } = payload

            if (!phaseId) return

            state.value.phases[phaseId] ??= { tasks: {} }
            state.value.phases[phaseId].tasks ??= {}

            const { isNew, isNewSaved } = state.value.phases[phaseId]?.tasks?.[taskId] || {}


            if (isNew || isNewSaved) {
                delete state.value.phases[phaseId].tasks?.[taskId]
                state.value.phases[phaseId].tasksIds = state.value.phases[phaseId].tasksIds?.filter?.(task => task !== taskId)

            } else {
                state.value.phases[phaseId].tasks[taskId] ??= {}
                state.value.phases[phaseId].tasks[taskId].isDeleted = true

            }

        },
        recoverTaskFromPhase: (state, { payload }) => {

            const { taskId, phaseId } = payload

            if (!phaseId) return

            state.value.phases[phaseId] ??= { tasks: {} }
            state.value.phases[phaseId].tasks[taskId] ??= {}

            state.value.phases[phaseId].tasks[taskId].isDeleted = false

        },
        //This action expects the tasksIds sorted
        resortTasks: (state, { payload }) => {

            const { phaseId, tasksSorted } = payload

            if (!Array.isArray(tasksSorted)) return

            state.value.phases[phaseId].tasksIds = tasksSorted

        },
        addPlanModuleToPhase: (state, { payload: { module, plan, phaseId } }) => {

            if ((!module && !plan) || !phaseId) return

            //if module/plan is already saved then return
            if (state.value.phases[phaseId]?.modules?.[module?.moduleId]) return
            if (state.value.phases[phaseId]?.plans?.[plan?.planId]) return

            state.value.phases[phaseId] ??= { plans: {}, modules: {} }

            if (module) {

                state.value.phases[phaseId].modules ??= {}
                state.value.phases[phaseId].modules[module.moduleId] = { ...module, isNew: true }
                return

            }

            state.value.phases[phaseId].plans ??= {}
            state.value.phases[phaseId].plans[plan.planId] = { ...plan, isNew: true }

        },
        removePlanModuleFromPhase: (state, { payload: { moduleId, planId, phaseId } }) => {

            if ((!moduleId && !planId) || !phaseId) return

            state.value.phases[phaseId] ??= { plans: {}, modules: {} }


            if (moduleId) {

                const { isNew } = state.value.phases[phaseId].modules?.[moduleId] || {}

                if (isNew) {
                    delete state.value.phases[phaseId].modules[moduleId]
                    return
                }

                state.value.phases[phaseId].modules[moduleId] ??= {}
                state.value.phases[phaseId].modules[moduleId].isDeleted = true

                return

            }

            const { isNew } = state.value.phases[phaseId].plans?.[planId] || {}

            if (isNew) {
                delete state.value.phases[phaseId].plans[planId]
                return
            }

            state.value.phases[phaseId].plans[planId] ??= {}
            state.value.phases[phaseId].plans[planId].isDeleted = true
        },
        recoverPlanModuleFromPhase: (state, { payload: { moduleId, planId, phaseId } }) => {

            if (!phaseId) return

            if (moduleId) {

                state.value.phases[phaseId] ??= { modules: {} }
                state.value.phases[phaseId].modules[moduleId] ??= {}

                state.value.phases[phaseId].modules[moduleId].isDeleted = false

                return
            }

            state.value.phases[phaseId] ??= { plans: {} }
            state.value.phases[phaseId].plans[planId] ??= {}

            state.value.phases[phaseId].plans[planId].isDeleted = false

        }
    },
})

export const { cleanPhasesToUpdate,
    openAddPhasesModal,
    closeAddPhasesModal,
    setPhasesSelectedOnTable,
    addTaskToPhase,
    removeTaskFromPhase,
    recoverTaskFromPhase,
    updatePhase,
    skiptUpdate,
    resortTasks,
    recoverPlanModuleFromPhase,
    removePlanModuleFromPhase,
    addPlanModuleToPhase
} = phasesSlice.actions

export default phasesSlice.reducer