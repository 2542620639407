import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import '../../styles/common.scss';
import { 
    Checkable,
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    CardBody,
    Avatar,
    AvatarAddOn,
    Media,
    Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Nav,
    NavItem, 
    NavLink,
    CustomInput,
    Badge,
} from './../../components';
import { randomAvatar } from './../../utilities';


import { LOCALES } from '../../i18nProvider';


const LanguageSelect = () => {

    
    function changeLang(LOCALES) {
        localStorage.setItem("locale", LOCALES)
        // setReload(!reload)
        location.reload()
    }
    
        // const [reload, setReload] = useState();
        if (!localStorage.locale) {
            localStorage.setItem("locale", LOCALES.ENGLISH);
        }

    return(
        <>
        
<div>
        {/* <FormattedMessage id="hello" /> */}
        {/* <p>
            {translate('app.header', {file: <code>src/App.js</code>})}
</p> */}

        {/* <button type="button" onClick={() => changeLang(LOCALES.ENGLISH)}>English</button>
        <button type="button" onClick={() => changeLang(LOCALES.SPANISH)}>Spanish</button> */}
</div>
        

                            <Media className="justify-content-center">
                           
                                <Media  top className="mr-4 cursor" onClick={() => changeLang(LOCALES.ENGLISH)}>
                                    <Avatar.Image
                                        size="sm"
                                        src={require("../../images/avatars/en.jpg")}    
                                    /> 
                                </Media>
                           
                           
                                {/* <Media  top className="mr-4 cursor" onClick={() => changeLang(LOCALES.PORTUGUESE)}>
                                    <Avatar.Image
                                        size="sm"
                                        src={require("../../images/avatars/pt.jpg")}
                                    /> 
                                </Media> */}
                                
                                
                            </Media>
  
</>

    )
}

export  { LanguageSelect };