import React from 'react';
import PropTypes from 'prop-types';

const FooterText = (props) => {

	//get the current year
	const currentYear = new Date().getFullYear()

	return (<React.Fragment>
		(C) {currentYear} All Rights Reserved.
		Designed and implemented by{' '}
		<a
			href="https://sprwt.io/"
			target="_blank"
			rel="noopener noreferrer"
			className="sidebar__link"
		>
			Sprwt.io
		</a>



	</React.Fragment>)
}
FooterText.propTypes = {
	year: PropTypes.node,
	name: PropTypes.node,
	desc: PropTypes.node,
};
FooterText.defaultProps = {
	year: "2021",
	name: "Dashboard",
	desc: "Centralized Dashboard software by Slickviewstudios"
};

export { FooterText };
