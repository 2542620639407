import React, { useRef, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Label,
} from '../../components';
import { updatePicture } from './updatePicture';


export let SetPictureModal = ({ opened, close, setUserPicture }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [image, setImage] = useState(null)
    const [imageRemoved, setImageRemoved] = useState(false)
    const pictureInputRef = useRef()

    let user

    try {
        user = JSON.parse(localStorage.user)
    } catch (err) {
        console.error({ err })
    }

    const userPicture = user?.user?.picture

    const onImageChange = (event) => {
        setImageRemoved(false)
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }

    const closeAndClean = () => {
        setLoading(false)
        setError(false)
        close()
    }

    let handleUpdate = async (ev) => {

        ev.preventDefault()
        setLoading(true)

        try {
            const picture = await updatePicture({ ev, imageRemoved }) || null
            setUserPicture(picture)
            closeAndClean()
        }
        catch (err) {
            console.error({ err })
            setError('Couldn\'t update picture')
        } finally {
            setLoading(false)
            close()
        }

    }

    const removePicture = () => {
        setImageRemoved(true)
        setImage(null)
    }

    return (
        <Modal
            isOpen={opened}
            size={'xl'}
            toggle={() => closeAndClean()}
        >
            <ModalBody className='py-5 text-center'>
                {error && <div className='text-warning mb-3'>{error}</div>}
                <form
                    id='updatePictureForm'
                    className='d-flex flex-column align-items-center'
                    onSubmit={handleUpdate}
                >
                    <Label for='userProfilePicture' className='mb-4'>
                        {image || (!imageRemoved && userPicture) ? <img
                            src={image || (!imageRemoved && userPicture)}
                            style={{
                                width: '30vh',
                                height: '30vh',
                            }}
                        /> :
                            <div
                                className='rounded-circle bg-light d-flex justify-content-center align-items-center border'
                                style={{
                                    width: '30vh',
                                    height: '30vh',
                                }}
                            >
                                <i
                                    className='fa fa-fs fa-user text-muted'
                                    style={{ fontSize: '10vh' }}
                                />
                            </div>
                        }
                    </Label>
                    <input
                        type="file"
                        ref={pictureInputRef}
                        onChange={onImageChange}
                        name="userProfilePicture"
                        style={{ display: 'none' }}
                        id="userProfilePicture"
                        accept="image/*"
                    />
                    <div>
                        <Button
                            outline
                            color="secondary"
                            className='mx-1'
                            onClick={removePicture}
                        >
                            Remove
                        </Button>
                        <Button
                            outline
                            color="success"
                            className='mx-1'
                            onClick={() => { pictureInputRef.current.click() }}
                        >
                            Change
                        </Button>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="link"
                    onClick={closeAndClean}
                >
                    <i className="fa fa-close mr-2"></i>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    form='updatePictureForm'
                    color="success"
                    className="text-primary"
                >
                    {loading ?
                        <span style={{ color: "white" }}><i className="fa fa-spinner fa-spin mr-2"></i>Update</span> :
                        <span style={{ color: "white" }}><i className="fa fa-save mr-2"></i>Update</span>
                    }
                </Button>
            </ModalFooter>
        </Modal>
    );
}
