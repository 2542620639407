import * as actions from '../actions/sampleActions'

export const initialState = {
  payload: "something",
  something: "something else",
  driver: "someone"
}

export default function sampleReducer(state = initialState, action) {
    if(action.type == "GET_DRIVER"){
      return { ...state, driver: "billie" }
    } else {
      return state
    }
}