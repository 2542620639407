import React from 'react'
import { Button } from 'reactstrap'

export default function SelectSavedCard({ toggleShowSavedCards, showSavedCards }) {
  return (
    <div className='btn text-primary px-4 py-2'
      style={{
        backgroundColor: '#9BDAFF',
        border: 'none',
        fontSize: 'small',
      }}
      onClick={() => toggleShowSavedCards?.()}
    >
      <i className='fa fa-fs fa-credit-card mr-2' />
      {showSavedCards ? 'Add a new card' : 'Select a saved card'}
    </div>
  )
}