import { createSlice } from '@reduxjs/toolkit'

export const cardsSlice = createSlice({
    name: 'cards',
    initialState: { value: { cardSubmited: {}, paymentMethod: {} } },
    reducers: {
        setCard: (state, { payload }) => {
            state.value = { ...state.value, cardSubmited: payload }
        },
        setPaymentMethod: (state, { payload }) => {
            state.value = { ...state.value, paymentMethod: payload }
        }
    },
})

export const { setCard, setPaymentMethod } = cardsSlice.actions

export default cardsSlice.reducer