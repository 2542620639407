import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../api';

import {
    Form,
    FormGroup,
    FormText,
    Input,
    Button,
    Label,
    EmptyLayout,
    ThemeConsumer
} from '../../../components';

let axiosConfig = {headers: {Authorization: localStorage.token}};
import { HeaderAuth } from "../../components/Pages/HeaderAuth";
import { FooterAuth } from "../../components/Pages/FooterAuth";

const UpdatePassword = () => {
    const history = useHistory();
    const [email, setEmail] = useState(null)
    const [code, setCode] = useState(null)
    const [password, setPassword] = useState(null)
    const [error, setError] = useState(null)
    function changePassword(e) {
        e.preventDefault()
        if(email==null){
            setError("email is required")
        } else if (code==null){
            setError("code is required")
        } else if(password==null){
            setError("password is required")
        } else {
            let payload = { email: email, code: code, password: password }
            axios.post(`${config.baseUrl}/users/recoverypasswordconfirmation`, payload, axiosConfig)
            .then(function (response) {
                history.push("/login")
            })
            .catch(function (error) {
                /* setError(error.response.data.error.toString()) */
                console.log(error)
            });
        }

    }
    return(
    <EmptyLayout>
        <EmptyLayout.Section center>
            { /* START Header */}
            <HeaderAuth
                title="Update Password"
                text="Welcome to Sprwt Meal Prep Software. Please enter a new password to login. If you need help please contact our support team."
            />
            { /* END Header */}
            { /* START Form */}
            <Form className="mb-3">
                {error ? <p className="text-danger">{error}</p> : ""}
                <FormGroup>
                    <Label for="emailAdress">
                        New Password
                    </Label>
                    <Input required value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="Enter new password..." className="bg-white" />
                    <FormText color="muted">
                        Do not share your password with anyone
                    </FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="emailAdress">
                        Confirm Password
                    </Label>
                    <Input required value={password} onChange={(e) => setPassword(e.target.value)} type="password" name="password" id="password" placeholder="Enter new password..." className="bg-white" />
                    <FormText color="muted">
                        Do not share your password with anyone
                    </FormText>
                </FormGroup>
                <div className="d-flex">
                    <ThemeConsumer>
                    {
                        ({ color }) => (
                            <Button color={ color } tag={ Link } onClick={(e) => changePassword(e)} className="align-self-center btn-block">
                                Change Password
                            </Button>
                        )
                    }
                    </ThemeConsumer>
                </div>
                <div className="d-flex">
                    <Button color="link" tag={ Link } to="/" className="align-self-center mr-auto pr-0 text-decoration-none">
                        <i className="fa fa-angle-left mr-2"></i> Back to Home
                    </Button>
                </div>
            </Form>
            { /* END Form */}
            { /* START Bottom Links */}
            <div className="d-flex mb-5">
                <Link to="/login" className="text-decoration-none">
                    Login
                </Link>
                <Link to="/register" className="ml-auto text-decoration-none">
                    Register
                </Link>
            </div>
            { /* END Bottom Links */}
            { /* START Footer */}
            <FooterAuth />
            { /* END Footer */}
        </EmptyLayout.Section>
    </EmptyLayout>
)}

export default UpdatePassword;
