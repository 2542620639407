import { createSlice } from '@reduxjs/toolkit'

export const subscriptionsSlice = createSlice({

    name: 'subscriptions',
    initialState: { value: {} },
    reducers: {
        setSubscription: (state, { payload }) => {

            state.value = { ...state.value, ...payload };

        },
        cleanSubscription: (state) => {
            state.value = {}
        }
    },
})

export const {
    setSubscription,
    cleanSubscription
} = subscriptionsSlice.actions

export default subscriptionsSlice.reducer