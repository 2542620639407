import axios from "axios";
import createCompany from "./createCompany";
import config from "../../../../api";


const createUser = async (data) => {

    const response = await axios.post(`${config.baseUrl}/users/register`, data)

    const hasError = "error" in response && response.error != null;
    if (hasError) throw { error: response.error }
    
}

export default createUser