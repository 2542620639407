import React from 'react'

export default function LoadingCover() {
    return (
        <div className='w-100 h-100 d-flex justify-content-center align-items-center position-absolute'
            style={{ top: 0, left: 0, backgroundColor: "hsla(0, 0%, 80%, 0.2)" }}>
            <i className='fa fa-spinner fa-spin'></i>
        </div>
    )
}

export { LoadingCover };