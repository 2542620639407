import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LogoThemed } from './../LogoThemed/LogoThemed';
import {
    HolderProvider,
    CardImg
} from '../../../components';

const HeaderAuth = (props) => (
    <div className="mb-4">
        <div className="mb-4 text-center">
                {
                    props.icon ? (

                        <Link to="/" className="d-inline-block">
                            <i className={ `fa fa-${ props.icon } fa-3x ${ props.iconClassName }` }></i>
                        </Link>
                    ) : props.image ? (
                        <HolderProvider.Icon
                            iconChar=""
                            size={ 32 }
                            className=""
                        >
                            <CardImg src={require(`./../../../images/content/${props.image}`)} alt=""  className="img-fluid"/>
                        </HolderProvider.Icon>
                    ) :
                    <Link to="/" className="d-inline-block">
                        <LogoThemed checkBackground height="60" />
                    </Link>
                }
        </div>
        <h5 className="text-center mb-4">
            { props.title }
        </h5>
        <p className="text-center">
            { props.text }
        </p>
    </div>
)
HeaderAuth.propTypes = {
    image: PropTypes.node,
    icon: PropTypes.node,
    iconClassName: PropTypes.node,
    title: PropTypes.node,
    text: PropTypes.node,
};
HeaderAuth.defaultProps = {
    title: "Waiting for Data...",
    text: "Welcome to Sprwt Meal Prep Software. Please enter your email and password to login. If you need help please click forgot password or contact our support team.",
    iconClassName: "text-theme"
};

export { HeaderAuth };
