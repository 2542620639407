import React, { lazy, Suspense } from 'react';
import axios from 'axios';
import config from '../../api'
import {
    Route,
    Switch,
    Redirect
} from 'react-router';
import changelog from "./../../changelog"

// ----------- Pages Imports ---------------
const Loading = () => (<h3 className="fw-300"><i className="fa fa-spinner fa-spin" /> Loading </h3>)
const Wizard = (
    lazy(() => import('./Dashboards/Wizard/Wizard'))
)
const Planpicker = (
    lazy(() => import('./Dashboards/Planpicker/Planpicker'))
)

const Plans = (
    lazy(() => import('./Dashboards/ActivePlans/ActivePlans'))
)
const Products = (
    lazy(() => import('./Dashboards/Products/Products'))
)

const Profile = (
    lazy(() => import('./Dashboards/Profile'))
)

const CustomerModules = (
    lazy(() => import('./Dashboards/Modules'))
)

const ProfileClient = (
    lazy(() => import('./Dashboards/ProfileClient'))
)
const Admins = (
    lazy(() => import('./Dashboards/Admins/Admins'))
)

// const salesDashboard = (
//     lazy(()=>import('./sales/Dashboard/salesDashboard'))
// )
const UserOnboard = (
    lazy(() => import('./Dashboards/Onboard'))
)

// COMPANIES

const Companies = (
    lazy(() => import('./Company/Companies'))
)

// CLIENT DASHBOARD

const ClientDashboard = (
    lazy(() => import('./Client/Dashboard/Dashboard'))
)
const NewClientSubscription = (
    lazy(() => import('./Client/Subscription/NewSubscription'))
)

const Home = (
    lazy(() => import('./Dashboards/Home'))
)
const ProductPlans = (
    lazy(() => import('./Dashboards/Home/components/ProductPlans'))
)
// SUPER ADMIN ROLES

const AdminDashboard = (
    lazy(() => import('./SuperAdmin/Dashboard/Dashboard'))
)
const AdminRoles = (
    lazy(() => import('./SuperAdmin/Roles/Roles'))
)
const NewAdminRole = (
    lazy(() => import('./SuperAdmin/Roles/NewRole'))
)
const EditAdminRole = (
    lazy(() => import('./SuperAdmin/Roles/EditRole'))
)
const ViewAdminRole = (
    lazy(() => import('./SuperAdmin/Roles/ViewRole'))
)

//Onboarder routes
const onboardDashboard = (
    lazy(() => import('./Onboarder/Dashboard/onboarderDashboard'))
)
const ClientDetails = (
    lazy(() => import('./Onboarder/ClientDetails/clientDetails'))
)
const ClientOnboard = (
    lazy(() => import('./Onboarder/ClientDetails/clientOnboard'))
)
// import onboardDashboard from './Onboarder/Dashboard/onboarderDashboard';
// import ClientDetails from './Onboarder/ClientDetails/clientDetails';
const Disputes = (
    lazy(() => import('./Disputes/index.js'))
)

const Onboards = (
    lazy(() => import('./SuperAdmin/Onboards/index.js'))
)

const editOnboard = (
    lazy(() => import('./SuperAdmin/Onboards/components/Onboards/Onboard'))
)

const createOnboard = (
    lazy(() => import('./SuperAdmin/Onboards/components/Onboards/CreateOnboard'))
)

const Phases = (
    lazy(() => import('./SuperAdmin/Onboards/components/Phases'))
)

const Phase = (
    lazy(() => import('./SuperAdmin/Onboards/components/Phases/PhaseEdit'))
)

const Tasks = (
    lazy(() => import('./SuperAdmin/Onboards/components/Tasks'))
)

const Task = (
    lazy(() => import('./SuperAdmin/Onboards/components/Tasks/TaskEdit'))
)

const SuperAdminCompanies = (
    lazy(() => import('./SuperAdmin/Companies'))
)

const SuperAdminCompany = (
    lazy(() => import('./SuperAdmin/Companies/Components/Company'))
)

const SuperadminProducts = (
    lazy(() => import('./SuperAdmin/Products'))
)

const SuperadminNewProduct = (
    lazy(() => import('./SuperAdmin/Products/Components/NewProduct'))
)

const SuperadminProduct = (
    lazy(() => import('./SuperAdmin/Products/Components/Product'))
)

const Modules = (
    lazy(() => import('./SuperAdmin/Modules'))
)

const CRON = (
    lazy(() => import('./SuperAdmin/CRON'))
)

const Module = (
    lazy(() => import('./SuperAdmin/Modules/Components/Module'))
)

const NewModule = (
    lazy(() => import('./SuperAdmin/Modules/Components/NewModule'))
)

const SuperAdminPlans = (
    lazy(() => import('./SuperAdmin/Plans'))
)

const Plan = (
    lazy(() => import('./SuperAdmin/Plans/Components/Plan'))
)

const NewPlan = (
    lazy(() => import('./SuperAdmin/Plans/Components/NewPlan'))
)

const Users = (
    lazy(() => import('./SuperAdmin/Users'))
)

const NewUser = (
    lazy(() => import('./SuperAdmin/Users/Components/NewUser'))
)

const User = (
    lazy(() => import('./SuperAdmin/Users/Components/User'))
)

const Admin = (
    lazy(() => import('./SuperAdmin/Users/Components/Admin'))
)

const UserPlan = (
    lazy(() => import('./SuperAdmin/Users/Components/Plans/UserPlan'))
)

const SuperAdminStats = (
    lazy(() => import('./SuperAdmin/Stats'))
)

//sales routes
const salesDashboard = (
    lazy(() => import('./SalesDashboard/Dashboard/SalesDashboard'))
)

const UnassignedCustomers = (
    lazy(() => import('./UnassignedUsers'))
)

const RelatedCustomers = (
    lazy(() => import('./RelatedUsers'))
)

const NotAssignedCustomers = (
    lazy(() => import('./NotAssignedUsers'))
)

const CompanySetUp = (
    lazy(() => import('./CompanySetUp'))
)

import CompanyDetails from './Dashboards/CompanyDetails/CompanyDetails';
/* import Users from './Dashboards/Users/Users'; */
import ChangeLog from './ChangeLog';

//Navbars
import NavbarOnly from './Layouts/NavbarOnly';
import SidebarWithNavbar from './Layouts/SidebarWithNavbar';

//utility pages
import ComingSoon from './Pages/ComingSoon';
import Confirmation from './Pages/Confirmation';
import Danger from './Pages/Danger';
import Error404 from './Pages/Error404';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword.js';
import ForgotPasswordConfirm from './Pages/ForgotPassword/ForgotPasswordConfirm.js';
import UpdatePassword from './Pages/UpdatePassword/UpdatePassword.js';
import LockScreen from './Pages/LockScreen';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Success from './Pages/Success';

// ----------- Layout Imports ---------------
import { DefaultNavbar } from './../layout/components/DefaultNavbar';

import { SidebarANavbar } from './../layout/components/SidebarANavbar';
import { SidebarASidebar } from './../layout/components/SidebarASidebar';
import { DefaultSidebar } from './../layout/components/DefaultSidebar';
import useFromLocalStorage from '../hooks/useFromLocalStorage';


const authGuard = (Component) => () => {
    if (changelog[0].version != localStorage.v) {
        localStorage.clear()
        sessionStorage.clear()
        return <Redirect to="/login" />
    }
    if (localStorage.getItem("token")) {
        let axiosConfig = {
            headers: {
                Authorization: localStorage.token
            }
        };
        axios.get(`${config.baseUrl}/users/me`, axiosConfig)
            .then(function (response) {
                if (response.data.data) {
                    localStorage.setItem('user', JSON.stringify(response.data.data))
                    if (sessionStorage.adminMode !== 'customer' && !sessionStorage.adminMode) {
                        sessionStorage.setItem('adminMode', response?.data?.data?.user?.role?.toLowerCase?.() || 'admin')
                    }
                } else {
                    localStorage.clear()
                    window.location = "/"
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        return <Suspense fallback={<Loading />}><Component /></Suspense>
    } else {
        return <Redirect to="/login" />
    }
};

const sudoGuard = (Component) => () => {
    if (localStorage.getItem("token") && localStorage.getItem("sudo")) {
        let axiosConfig = {
            headers: {
                Authorization: localStorage.token
            }
        };
        axios.get(`${config.baseUrl}/users/me`, axiosConfig)
            .then(function (response) {
                if (response.data.data) {
                    localStorage.setItem('user', JSON.stringify(response.data.data))
                } else {
                    localStorage.clear()
                    window.location = "/"
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        return <Suspense fallback={<Loading />}><Component /></Suspense>
    } else {
        return <Redirect to="/login" />
    }
};

function handleHome() {

    let adminMode
    const company = useFromLocalStorage('company')

    try {
        adminMode = JSON.parse(localStorage.getItem('user'))?.user?.role?.toLowerCase()
        if (!sessionStorage.adminMode) {
            sessionStorage.setItem('adminMode', adminMode)
        }
    } catch (error) {
        console.error(error)
    }

    if (!localStorage.getItem("token")) {
        return "/login"
    }
    if (localStorage.getItem("token") && localStorage.getItem("user") && JSON.parse(localStorage.user).user && !JSON.parse(localStorage.user).user.verified) {
        return "/confirmation"
    }

    if ((localStorage.getItem("token") && localStorage.getItem("sudo")) || adminMode === 'superadmin') {
        return "/superadmin"
    } else if (adminMode === 'sales') {
        return "/sales"
    } else if (adminMode === 'onboarder') {
        return "/onboarder"
    } else if (localStorage.getItem("token") && JSON.parse(localStorage.user).companies?.length == 1) {
        return "/dashboard/home"
    } else if (localStorage.getItem("token") && JSON.parse(localStorage.user).companies?.length > 1) {
        return "/companies"
    } else if (localStorage.getItem("token") && company && company?.plan == null) {
        return "/planpicker"
    } else if (localStorage.getItem("token") && localStorage.getItem("company") && localStorage.getItem("status") < 4) {
        return "/wizard"
    } else if (localStorage.getItem("token")) {
        return "/dashboard/home"
    }
    else {
        return "/pages/error-404"
    }
};

const isNotLogged = (Component) => () => {
    if (!localStorage.getItem("token")) {
        return <Component />
    } else {
        return <Redirect to="/" />
    }
};


//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars
export const RoutedContent = () => {

    return (
        <Switch>

            <Redirect from="/" to={handleHome()} exact />

            { /* Admin Routes */}
            <Route path="/wizard" exact component={authGuard(Wizard)} />
            <Route path="/planpicker" exact component={authGuard(Planpicker)} />


            {/* Pages with authguard */}
            <Route component={authGuard(UpdatePassword)} path="/update-password" exact />

            <Route path="/superAdmin/companyDetails/:id" exact component={sudoGuard(CompanyDetails)} />
            {/* <Route path="/superAdmin/users" exact component={sudoGuard(Users)} /> */}
            <Route path="/superAdmin/version" exact component={sudoGuard(ChangeLog)} />

            {/* ////////////////////////// DASHBOARD ROUTES /////////////////////////////////  */}

            {/* ///// CLIENT ROUTES ///// */}
            <Route path="/dashboard/home" exact component={authGuard(ClientDashboard)} />
            <Route path="/dashboard/home/product/:productId/plans" exact component={authGuard(ProductPlans)} />
            <Route path="/dashboard/plans" exact component={authGuard(Plans)} />
            <Route path="/dashboard/profile" exact component={authGuard(Profile)} />
            <Route path="/dashboard/products" exact component={authGuard(Products)} />
            <Route path="/dashboard/products/:productId/onboard" exact component={authGuard(UserOnboard)} />
            <Route path="/dashboard/products/:productId/modules" exact component={authGuard(CustomerModules)} />
            <Route path="/dashboard/admins" exact component={authGuard(Admins)} />
            <Route path="/companies" exact component={authGuard(Companies)} />{/* List all companies available if have more than 1 */}
            <Route path="/companies/:companyId/subscriptions" exact component={authGuard(ClientDashboard)} />
            <Route path="/companies/:companyId/subscriptions/new" exact component={authGuard(NewClientSubscription)} />
            <Route path="/companies/:companyId/subscriptions/:subscriptionId" exact component={authGuard(ClientDashboard)} />
            <Route path="/companies/:companyId/subscriptions/:subscriptionId/edit" exact component={authGuard(ClientDashboard)} />

            {/** Client routes for new or existing company */}
            <Route path="/company-set-up" exact component={authGuard(CompanySetUp)} />


            {/* ///// SUPER ADMIN ROUTES ///// */}
            <Route path="/superadmin" exact component={sudoGuard(AdminDashboard)} />
            {/* Super Admin Roles */}
            <Route path="/superadmin/roles" exact component={sudoGuard(AdminRoles)} />
            <Route path="/superadmin/roles/new" exact component={sudoGuard(NewAdminRole)} />
            <Route path="/superadmin/roles/:roleId/edit" exact component={sudoGuard(EditAdminRole)} />
            <Route path="/superadmin/roles/:roleId" exact component={sudoGuard(ViewAdminRole)} />
            <Route path="/superadmin/customer/:userId/product/:productId/onboard" exact component={sudoGuard(ClientOnboard)} />
            {/* End Super Admin Roles */}
            {/* Disputes Routes */}
            <Route path="/superadmin/disputes" exact component={authGuard(Disputes)} />
            {/* Onboards Routes */}
            <Route path="/superadmin/onboards" exact component={authGuard(Onboards)} />
            <Route path="/superadmin/onboards/create" exact component={authGuard(createOnboard)} />
            <Route path="/superadmin/onboards/edit/:onboardId" exact component={authGuard(editOnboard)} />
            <Route path="/superadmin/onboards/phases/" exact component={authGuard(Phases)} />
            <Route path="/superadmin/onboards/phases/:phaseId" exact component={authGuard(Phase)} />
            <Route path="/superadmin/onboards/tasks" exact component={authGuard(Tasks)} />
            <Route path="/superadmin/onboards/tasks/:taskId" exact component={authGuard(Task)} />
            {/*Companies routes */}
            <Route path="/superadmin/companies" exact component={authGuard(SuperAdminCompanies)} />
            <Route path="/superadmin/companies/edit/:companyId" exact component={authGuard(SuperAdminCompany)} />
            {/*Products routes */}
            <Route path="/superadmin/products" exact component={authGuard(SuperadminProducts)} />
            <Route path="/superadmin/products/edit/:productId" exact component={authGuard(SuperadminProduct)} />
            <Route path="/superadmin/products/new" exact component={authGuard(SuperadminNewProduct)} />
            {/*Modules Routes*/}
            <Route path="/superadmin/modules" exact component={authGuard(Modules)} />
            <Route path="/superadmin/modules/new" exact component={authGuard(NewModule)} />
            <Route path="/superadmin/modules/edit/:moduleId" exact component={authGuard(Module)} />
            {/* CRON routes */}
            <Route path="/superadmin/cron" exact component={sudoGuard(CRON)} />
            {/*Plans Routes*/}
            <Route path="/superadmin/plans" exact component={authGuard(SuperAdminPlans)} />
            <Route path="/superadmin/plans/new" exact component={authGuard(NewPlan)} />
            <Route path="/superadmin/plans/edit/:planId" exact component={authGuard(Plan)} />
            {/*Users Routes*/}
            <Route path="/superadmin/users" exact component={authGuard(Users)} />
            <Route path="/superadmin/users/new" exact component={authGuard(NewUser)} />
            <Route path="/superadmin/users/customer/edit/:userId" exact component={authGuard(User)} />
            <Route path="/superadmin/users/admin/edit/:userId" exact component={authGuard(Admin)} />
            <Route path="/superadmin/users/:userId/plan/:planId" exact component={authGuard(UserPlan)} />

            {/* ///// SUPERADMIN STATS ///// */}
            <Route path="/superadmin/stats" exact component={authGuard(SuperAdminStats)} />

            {/* ///// END SUPER ADMIN ROUTES ///// */}


            { /* Onboarder Routes */}
            <Route path="/onboarder" exact component={authGuard(onboardDashboard)} />
            <Route path="/onboarder/focususer/:userId" exact component={authGuard(onboardDashboard)} />
            <Route path="/onboarder/client/:userId" exact component={authGuard(ClientDetails)} />
            <Route path="/onboarder/client/:userId/product/:productId" exact component={authGuard(ClientOnboard)} />
            <Route path="/onboarder/client/:userId/product/:productId/onboard/:onboardId" exact component={authGuard(ClientOnboard)} />
            <Route path="/onboarder/clients/focususer/:userId" exact component={authGuard(RelatedCustomers)} />

            { /* Sales Routes */}
            <Route path="/sales" exact component={authGuard(salesDashboard)} />
            <Route path="/sales/clients/focususer/:userId" exact component={authGuard(RelatedCustomers)} />
            <Route path="/sales/client/:userId" exact component={authGuard(ClientDetails)} />
            <Route path="/sales/users/:userId/plan/:planId" exact component={authGuard(UserPlan)} />

            {/* Common sales and onboard route */}

            <Route path="/sales/clients" exact component={authGuard(RelatedCustomers)} />
            <Route path="/sales/unassignedcustomers" exact component={authGuard(NotAssignedCustomers)} />
            <Route path="/onboarder/clients" exact component={authGuard(NotAssignedCustomers)} />
            <Route path='/onboarder/unassignedcustomers' component={authGuard(UnassignedCustomers)} />
            <Route path="/onboarder/users/:userId/plan/:planId" exact component={authGuard(UserPlan)} />

            {/* ////////////////////////// END DASHBOARD ROUTES /////////////////////////////////  */}

            { /*    Pages Routes    */}
            <Route component={ComingSoon} path="/pages/coming-soon" />
            <Route component={Confirmation} path="/confirmation" />
            <Route component={Danger} path="/pages/danger" />
            <Route component={Error404} path="/pages/error-404" />
            <Route component={isNotLogged(ForgotPassword)} path="/forgot-password" exact />
            <Route component={isNotLogged(ForgotPasswordConfirm)} path="/forgot-password/confirm" exact />
            <Route component={LockScreen} path="/pages/lock-screen" />
            <Route component={isNotLogged(Login)} path="/login" />
            <Route component={isNotLogged(Register)} path="/register" />
            <Route component={Success} path="/pages/success" />

            { /*    404    */}
            <Redirect to="/pages/error-404" />
        </Switch>
    );
};

//------ Custom Layout Parts --------
export const RoutedNavbars = () => (
    <Switch>
        { /* Other Navbars: */}
        <Route
            component={SidebarANavbar}
            path="/layouts/sidebar-a"
        />
        <Route
            component={NavbarOnly.Navbar}
            path="/layouts/navbar"
        />
        <Route
            component={SidebarWithNavbar.Navbar}
            path="/layouts/sidebar-with-navbar"
        />
        { /* Default Navbar: */}
        <Route
            component={DefaultNavbar}
        />
    </Switch>
);

export const RoutedSidebars = () => (

    <Switch>
        { /* Other Sidebars: */}
        <Route
            component={SidebarASidebar}
            path="/layouts/sidebar-a"
        />
        <Route
            component={SidebarWithNavbar.Sidebar}
            path="/layouts/sidebar-with-navbar"
        />
        { /* Default Sidebar: */}
        <Route
            component={DefaultSidebar}
        />
    </Switch>

);
