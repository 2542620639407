import '@babel/polyfill';

import React, { useState } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import store from './reducers/store';
import App from './components/App';
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

render(

    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <App />
        </Provider>
    </QueryClientProvider>,
    document.querySelector('#root')

)