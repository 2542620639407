import { createSlice } from '@reduxjs/toolkit'

export const usersSlice = createSlice({

    name: 'users',
    initialState: { value: { selectedUser: {}, users: {} } },
    reducers: {
        setUser: (state, { payload }) => {
            state.value.selectedUser = payload
        },
        insertTagToUser: (state, { payload }) => {

            const { userId, tag } = payload

            if (!userId || !tag) return;

            //If tag is already in the user, do return
            if (state.value.users[userId]?.tagsIds?.includes(tag.tagId)) return;

            state.value.users[userId] ??= { tags: [] }

            state.value.users[userId].tags ??= []
            state.value.users[userId].tags.push({ ...tag, isDeleted: false })

        },
        removeTagFromUser: (state, { payload }) => {

            const { userId, tag } = payload
            const { name: tagName } = tag

            state.value.users[userId] ??= { tagsIds: [] }

            if (state.value.users[userId].tags.find(({ name }) => name === tagName).isNew) {
                state.value.users[userId].tags = state.value.users[userId].tags.filter(({ name }) => name !== tagName)
                return
            }

            state.value.users[userId].tags.find(({ name }) => name === tagName).isDeleted = true

        },
        recoverTagFromUser: (state, { payload }) => {

            const { userId, tagId } = payload

            state.value.users[userId] ??= { tags: {} }

            state.value.users[userId].tags[tagId].isDeleted = false

        },
        setCustomersImported: (state, { payload }) => {
            state.value.customersImported = payload
        },
        triggerUpdateUserInfo: (state, { payload }) => {

            const { userId } = payload
            state.value.users[userId] = { ...(state.value.users[userId] || {}), updateInfoId: Math.random() }
            console.log('tables updated')
        }
    },
})

export const {
    setUser,
    insertTagToUser,
    removeTagFromUser,
    recoverTagFromUser,
    setCustomersImported,
    triggerUpdateUserInfo
} = usersSlice.actions

export default usersSlice.reducer