import axios from 'axios'
import store from '../reducers/store'
import { updateErrorList } from '../reducers/features/errors'

const checkWarningsInHeaders = (headers) => {

    if (headers.inactive_subscription === 'true') {
        store.dispatch(updateErrorList('inactive_subscription'))
    }

}

const checkInvalidCompanyResponse = (response) => {
    
    if (response?.data?.error === 'Unauthorized Company' || response?.data?.error === 'Unauthorized UserCompany') {
        window.location.href = '/company-set-up'
    }
}

export let useReportAxiosErrors = () => {

    const interceptor = axios.interceptors.response.use(function (response) {

        checkWarningsInHeaders(response.headers)

        return response;
    },
        /**
         * Intercept any error and saved it in the redux state
         * @param {number} error.response.status - Errors code
         */
        function (error) {
            checkInvalidCompanyResponse(error?.response)
            store.dispatch(updateErrorList({ code: error?.response?.status }))
            throw error;
        });

    return () => { axios.interceptors.response.eject(interceptor) };

}