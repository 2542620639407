import React from "react";
import axios from "axios";
import config from "../../../api";
import { toast } from 'react-toastify';
import { ToastSuccess } from "../../routes/components/ToastSuccess";

const axiosConfig = {
    headers: {
        Authorization: localStorage.token,
        'Content-Type': 'multipart/form-data'
    }
};

export const updatePicture = async ({ ev, imageRemoved }) => {

    ev.preventDefault()
    const userId = JSON.parse(localStorage.user).user.userId

    //Get the form files
    const payload = new FormData()
    payload.append('userProfilePicture', ev.target.elements['userProfilePicture'].files[0])
    payload.append('userId', userId)

    //Check if the user image was removed
    const removeProfileImage = imageRemoved

    payload.append('removeProfileImage', removeProfileImage)

    //Send the data
    try {
        const result = await axios.put(`${config.baseUrl}/users/me/picture`, payload, axiosConfig)

        const picture = result.data?.data?.picture
        const user = JSON.parse(localStorage.user)
        user.user.picture = picture
        localStorage.setItem('user', JSON.stringify(user))

        toast.success(
            <ToastSuccess
                title={"Success!"}
                message={"Picture updated"}
            />
        );

        return picture

    } catch (err) {
        console.error({ err })
        toast.error(
            <ToastSuccess
                title={"Error"}
                message={"Could not update the picture"}
            />
        );
    }

}