import React from 'react';
import _ from 'lodash';

import { 
    Badge,
    UncontrolledTooltip,
    Table
} from './../../../../components';

/*eslint-disable */
const payment = [
    <Badge color="primary">
        Premium
    </Badge>,
    <Badge color="info">
        Basic
    </Badge>,
    <Badge color="warning">
        Pro
    </Badge>,
    <Badge color="danger">
        Advanced
    </Badge>,
    <Badge color="secondary">
        Free
    </Badge>
];
/*eslint-enable */
/*eslint-disable */
const receipt = [
    <td className="align-middle text-right">
        <a href="#" id="UncontrolledTooltipDownload">
            <i className="fa fa-fw fa-download text-primary"></i>
        </a>
        <UncontrolledTooltip placement="left" target="UncontrolledTooltipDownload">
            Download
        </UncontrolledTooltip>
    </td>
];
/*eslint-enable */
/*eslint-disable */
const status = [
    <td className="align-middle">
        <i className="fa fa-fw fa-check text-success"></i>
    </td>,
    <td className="align-middle">
        <i className="fa fa-fw fa-close text-danger"></i>
    </td>
];
/*eslint-enable */

const InvoicesTable = () => (
    <React.Fragment>
        <Table className="mb-0" hover responsive>
            <thead>
                <tr>
                    <th className="bt-0">Status</th>
                    <th className="bt-0">ID</th>
                    <th className="bt-0">Date</th>
                    <th className="bt-0">Amount</th>
                    <th className="bt-0">Description</th>
                    <th className="bt-0">Payment Method</th>
                    <th className="text-right bt-0">
                        Receipt
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                _.times(5, (index) => (
                    <tr key={ index }>
                        { status[0] }

                        <td className="align-middle">
                            <samp>1337</samp>
                        </td>

                        <td className="align-middle">
                            11/12/2020 14:30
                        </td>

                        <td className="align-middle text-inverse">
                            $ 1337
                        </td>

                        <td className="align-middle">
                            { payment[index] }
                        </td>

                        <td className="align-middle">
                            Visa 4*** **** **** 9221
                        </td>

                        { receipt }
                    </tr>
                ))
            }
            </tbody>
        </Table>
    </React.Fragment>
)

export { InvoicesTable };
